<template>
    <div class="SideBarContent justify-center">      
      <div class="d-none d-sm-none d-md-none d-lg-flex">
        <v-img id="actorPhoto" class="fotoUser py-5 mb-5" :src="selectedActorImage" lazy-src="./../../image/empty_photo.jpg"/>
      </div>
        <h4 class="text-center" style="color:#313131">{{selectedActorName()}}</h4>
        <div class="text-center text-caption">группа {{selectedActorGroup()}}</div>
        <div class="text-center text-caption">курс {{selectedActorDivisionLevel()}}</div>
      <div class="groopeButtons justify-center">        
        <!--v-btn icon title="Заблокировать">
            <v-icon color="red">mdi-account-off</v-icon>
        </v-btn>
        <v-btn icon title="Предупреждение">
            <v-icon color="yellow">mdi-alert-outline</v-icon>
        </v-btn>
        <v-dialog
          v-model="dialog"
          width="500"
          >
          <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs"
              v-on="on" 
              icon title="Отправить сообщение">
                <v-icon color="">mdi-email-outline</v-icon>
              </v-btn>
          </template>
              <SendMail />
        </v-dialog-->
        <v-btn icon title="Перейти к просмотру записи" @click="showSavedStreams">
            <v-icon color="">mdi-play</v-icon>
        </v-btn>
      </div>       
      <!--div class="bottomBtn">
        <v-btn small absolute bottom @click="$emit('toggle-view', 0)">
          <v-icon >mdi-close</v-icon><span class="text-caption">Закрыть</span>
        </v-btn>
      </div-->
      <v-dialog v-model="dialog" fullscreen transition="dialog-bottom-transition">
        <ViewSavedStreams :cameraSource="cameraSource"
                          :screenSource="screenSource"
                          @closeViewSavedStreamsDialog="closeViewSavedStreamsDialog"/>
      </v-dialog>
    </div>    
</template>

<script>
//import SendMail from './SendMail.vue'

import ViewSavedStreams from '../common/ViewSavedStreams'

export default {
  components: {
//    SendMail,
    ViewSavedStreams
    },
  name: "SideBarForCarouselStudents",
  data: () => ({
    dialog: false,
    cameraSource: null,
    screenSource: null
  }),
  props: { sessions: Array, selectedSessionIndex: Number },
  computed: {
    selectedActorImage: function() {
      return 'data:image/jpeg;base64,' + this.sessions[this.selectedSessionIndex].actorImage
    }
  },
  methods: {
    selectedActorName() {
      return this.sessions[this.selectedSessionIndex].actorName
    },
    selectedActorGroup() {
      return this.sessions[this.selectedSessionIndex].groupName
    },
    selectedActorDivisionLevel() {
      return this.sessions[this.selectedSessionIndex].actorDivisionLevel
    },
    closeViewSavedStreamsDialog() {
      this.dialog = false
      this.cameraSource = null
      this.screenSource = null
    },
    formatDate(stringDate) {
      let date = new Date(stringDate)
      return (date.getDate() > 9 ? date.getDate() : ("0" + date.getDate())) + "-" +
          (date.getMonth() + 1 > 9 ? date.getMonth() + 1 : ("0" + (date.getMonth() + 1))) + "-" +
          date.getFullYear() + " " +
          (date.getHours() > 9 ? date.getHours() : ("0" + date.getHours())) + ":" +
          (date.getMinutes() > 9 ? date.getMinutes() : ("0" + date.getMinutes()))
    },
    showSavedStreams() {
      this.dialog = true
      let cloneObject = function(srcObject) {
        let clone = {}
        for (let key in srcObject) {
          clone[key] = srcObject[key]
        }
        return clone
      }
      let cameraEvent = cloneObject(this.sessions[this.selectedSessionIndex])
      cameraEvent["endpointType"] = "RECORDING_CAMERA_ENDPOINT"
      let screenEvent = cloneObject(this.sessions[this.selectedSessionIndex])
      screenEvent["endpointType"] = "RECORDING_SCREEN_ENDPOINT"
      let cameraURL = `api/stream/${cameraEvent.endpointType}/${cameraEvent.sessionID}/${cameraEvent.eventID}/${cameraEvent.studentID}/${this.formatDate(cameraEvent.recordingStartTime).replace(" ", "_")}`
      let screenURL = `api/stream/${screenEvent.endpointType}/${screenEvent.sessionID}/${screenEvent.eventID}/${screenEvent.studentID}/${this.formatDate(screenEvent.recordingStartTime).replace(" ", "_")}`
      let self = this
      fetch(cameraURL, { headers: { 'Authorization': 'Bearer ' + this.$store.state.application.keycloak.token }})
          .then(response => response.blob())
          .then(blob => {
            self.cameraSource = URL.createObjectURL(blob)
          })
      fetch(screenURL, { headers: { 'Authorization': 'Bearer ' + this.$store.state.application.keycloak.token }})
          .then(response => response.blob())
          .then(blob => {
            self.screenSource = URL.createObjectURL(blob)
          })
    },
  }
}
</script>

<style>
.fotoUser {
  width: 100%;  
}
.blockUser {
  display: flex;
  align-items: flex-end;
}
.groopeButtons {
  display: flex;
}
.bottomBtn {
  justify-content: center;
  display: flex;
  margin-top: 10%;
}
</style>