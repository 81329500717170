<template>
  <v-container fluid>  
    <v-row>       
      <v-col md="10" sm="9" cols="12">
        <div class="float-right">
          <v-btn icon @click="$emit('toggle-view', 0)">
            <v-icon >mdi-close</v-icon>
          </v-btn>
        </div>        
        <div class="wrapper">
          <v-carousel :show-arrows="false" hide-delimiters v-model="currentSessionIndex" height="95%">
            <v-carousel-item v-for="item in sessions" :key="item.studentID">
              <div class="video_box_big">
                <div class="video_wrapper_big">
                  <div class="video">
                    <video :id="'bigCamera-' + item.sessionID"
                           class ="videoCamera" autoplay
                           :src-object.prop.camel="readStream('camera', item.studentID)"
                           @click="toggleVideoElements">
                    </video>
                  </div>
                  <div class="video_overlays">
                    <video :id="'bigScreen-' + item.sessionID" title="Кликните по экрану, что бы увидеть ближе рабочий стол"
                           class ="videoscreen" autoplay
                           :src-object.prop.camel="readStream('screen', item.studentID)"
                           @click="toggleVideoElements">
                    </video>
                  </div> 
                  <div class="video_overlays_name text-truncate">
                    <div v-if="item.disconnected" style="background-color: #dd1144"> {{item.actorName}} </div>
                    <div v-else> {{item.actorName}} </div>
                  </div>                
                </div>                 
              </div>
            </v-carousel-item>
          </v-carousel>
        </div>
      </v-col>
      <v-col md="2" sm="3" cols="12">                
        <div class="wrapper1">
          <div v-for="(item, i) in sessions" :key="item.sessionID">
            <div class="video_box" v-if="i !== currentSessionIndex" @click="setCurrentSessionIndex(i)">
              <div class="video_wrapper">
                <div class="video">
                  <video class ="videoCamera" poster="@/image/poster.png" autoplay muted
                        :src-object.prop.camel="readStream('camera', item.studentID)">
                  </video>
                </div>
                <div class="video_overlays">
                  <video class ="videoscreen" poster="@/image/poster.png" autoplay muted
                        :src-object.prop.camel="readStream('screen', item.studentID)">
                  </video>
                </div>              
              </div>
            </div>
          </div>
        </div>        
            
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "ViewCarouselStudentSessions",
  props: {
    sessions: Array,
    selectedSessionIndex: Number
  },
  data: () => ({
    currentSessionIndex: 0,
  }),
  mounted() {
    this.currentSessionIndex = this.selectedSessionIndex
  },
  computed: {
    ...mapGetters('application', { activeStreams: 'activeStreams', })
  },
  methods: {
    setCurrentSessionIndex(i) {
      this.currentSessionIndex = i
      this.$emit('set-current-session-index', i)
    },
    readStream(type, id) {
      let activeStreamID = type === "camera" ? "VIEW_CAMERA_ENDPOINT-" + id : "VIEW_SCREEN_ENDPOINT-" + id
      for (let i = 0; i < this.activeStreams.length; i++) {
        if (this.activeStreams[i].id === activeStreamID) {
          return this.activeStreams[i].stream
        }
      }
      return null
    },
    toggleVideoElements(event) {
      let clickedElement = document.getElementById(event.target.id)
      if (clickedElement && clickedElement.className !== "videoscreen") { return }
      let sessionID = event.target.id.startsWith("bigScreen-") ?
          event.target.id.slice("bigScreen-".length) : event.target.id.slice("bigCamera-".length)
      let nextElementType = event.target.id.startsWith("bigScreen-") ? "bigCamera-" : "bigScreen-"
      let nextElement = document.getElementById(nextElementType + sessionID)
      clickedElement.className = "videoCamera"
      clickedElement.parentElement.className = "video"
      nextElement.className = "videoscreen"
      nextElement.parentElement.className = "video_overlays"
    },    
  }
}
</script>

<style scoped>
.wrapper {
  display: grid;
  justify-items: center;
}

.wrapper1 {   
  overflow-y: auto;
  height: 85vh;
  padding-left: 10px;
  padding-right: 10px;
}

.overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  opacity: 0.5;
  transition: .3s ease;
  background-color: #599df7;
  min-height: 10%;
}

.overlay_small {
  position: absolute;
  bottom: 5px;
  left: 0;
  width: 100%;
  opacity: 0.5;
  transition: .3s ease;
  background-color: #599df7;
  height: 30%;
}

.pictograms {
  position: absolute;
  bottom: 0%;
  right: 0;
  height: 25%;
  width: 100%;
  opacity: 1;
  z-index: 1;
  padding: 2px;
  display: grid;
}

.pictograms_small {
  position: absolute;
  bottom: 0%;
  left: 0;
  height: 10%;
  width: 100%;
  opacity: 1;
  z-index: 1;
  padding: 2px;
}

.pictogram_icon {
  position: absolute;
  bottom: 7%;
}

.video_box {
  position:relative;  
  margin-bottom: 15px;    
}

.video_box_big {  
  width: 100%;
  justify-self: center;
  height: 81vh;
  background: #deddde;
}

.video_wrapper_big {
  position:relative;
  width: 100%;
  height: 100%;
  object-fit: none;
}

.video {
  width: 100%;
  height: 100%;
}

.videoCamera {
  width: 100%;
  height: 100%;
}

.video_overlays {
  position:absolute;
  width:35%;
  min-height:35%;
  z-index:300000;
  top:0px;
  right:0px;
  text-align:center;
}

.videoscreen {
  width:100%;
  min-height:100%;
  z-index:300000;
}
.btnMic {
  right: 0;
  position: absolute;
  bottom: 0;
}
video::-webkit-media-controls-fullscreen-button {
display: none;
}
video::-webkit-media-controls-picture-in-picture-button {
  display: none;
}
.video_overlays_name{
    position: absolute;
    top: 10px;
    left: 0;
    width: 30%;
    background-color: #006cb4;
    color: #fff;
    font-size: 18px;
    padding: 10px;
    opacity: 0.5;
}
</style>