<template>
  <div>
    <v-alert color="blue-grey" class="py-0" dark dense prominent>
      <v-row>
        <v-col class="d-none d-sm-flex" lg="9" md="8" sm="7" cols="12">
          <v-btn class="ma-2" fab dark color="grey" disabled>
            <v-icon large>mdi-school</v-icon>
          </v-btn>
          <span class="text-uppercase py-6">Участники ONLINE</span>
        </v-col>
      </v-row>
    </v-alert>
    <v-simple-table>
      <template v-slot:default>
        <thead>
        <tr>
          <th class="text-left">Мероприятие</th>
          <th class="text-left">Студент</th>
          <th class="text-left">Выбрано
            <Hint/>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="item in activeEvents" :key="item.studentID">
          <td><v-btn text small @click="selectItemEvent(item)">{{ item.eventName }}</v-btn></td>
          <td>{{ item.actorName }}</td>
          <td>
            <input type="checkbox" :id="item.studentID" v-model="item.selected" @change="changeActiveEventSelected($event)">
          </td>
        </tr>
        </tbody>
      </template>
    </v-simple-table>
    <v-dialog v-model="showEventInfoDialog" width="500" class="dialogStudents" style="background:#eceff1;">
      <HintInfoEvent :selectedEvent="selectedEvent" :eventParticipants="selectedEventParticipants" @closeEventInfoDialog="closeEventInfoDialog()"/>
    </v-dialog>
    <v-dialog v-model="needShowViews" fullscreen  class="d-flex">
      <v-toolbar >
        <div style="display:flex;">
          <v-img src="./../../image/logo.png" style="width: 25px;"></v-img>  
          <v-divider vertical style="margin-left: 10px; margin-right: 10px;"></v-divider>
          <div class="img_logo">
            <v-img src="./../../image/logo1.png" style="width: 95px;"></v-img>  
          </div>        
        </div>
        <v-spacer></v-spacer>

        <v-divider vertical style="margin-left: 10px; margin-right: 10px;"></v-divider>
        <div icon align="right">
          <v-btn icon @click="closeView">
            <v-icon>mdi-close</v-icon>            
          </v-btn>       
        </div>
      </v-toolbar>
      <div class="container-fluid" style="background:#ECEFF1;height: calc(100% - 64px);">  
        <v-row style="margin:0px;height: 100%">
          <v-col md="3" sm="12" cols="12" lg="2" style="padding: 0px;">        
            <div permanent style="background: #fff; height:100%; padding:10px;">                        
              <SideBarForGridStudents v-if="needViewGridStudentSessions"
                                          :sessions="selectedEvents()"
                                          @toggle-view="toggleView" />  
              <SideBarForCarouselStudents v-else
                                              :sessions="selectedEvents()"
                                              :selectedSessionIndex="selectedSessionIndex"
                                              @toggle-view="toggleView" />             
            </div>    
          </v-col>
          <v-col md="9" sm="12" cols="12" lg="10">
            <div class="" style="background:#ECEFF1;height:100%;">              
                <ViewGridStudentSessions v-if="needViewGridStudentSessions"
                                          :sessions="selectedEvents()"
                                          @toggle-view="toggleView" />
                <ViewCarouselStudentSessions v-else
                                              :sessions="selectedEvents()"
                                              :selectedSessionIndex="selectedSessionIndex"
                                              @set-current-session-index="setCurrentSessionIndex"
                                              @toggle-view="toggleView" />                     
            </div> 
          </v-col>
        </v-row>
      </div>
    </v-dialog>
    <v-card-text style="height: 100px; position: relative">
      <v-btn dark absolute bottom right color="primary"
             :disabled="showSelectedSessionsDisabled()"
             @click="showSelectedSessions()" >
        Просмотр
      </v-btn>
    </v-card-text>
    <v-dialog v-model="showSpinner" fullscreen>
      <v-progress-circular color="primary" indeterminate/>
    </v-dialog>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import Hint from './Hint.vue';
import ViewGridStudentSessions from './ViewGridStudentSessions.vue'
import ViewCarouselStudentSessions from './ViewCarouselStudentSessions.vue' 
import SideBarForGridStudents from './SideBarForGridStudents.vue' 
import SideBarForCarouselStudents from './SideBarForCarouselStudents.vue' 
import HintInfoEvent from './HintInfoEvent.vue'

export default {
  components: {
    Hint,
    ViewGridStudentSessions,
    ViewCarouselStudentSessions,
    SideBarForGridStudents,
    SideBarForCarouselStudents,
    HintInfoEvent
  },
  name: "ActiveEvents",
  data: () => ({
    showSpinner: false,
    needShowViews: false,
    needViewGridStudentSessions: true,
    selectedSessionIndex: 0,
    value: 1,
    drawer: true,        
    mini: true,
    dialog: false,
    showEventInfoDialog: false,
    selectedEvent: null,
    selectedEventParticipants: null
  }),
  computed: {
    ...mapGetters('application', { activeEvents: 'activeEvents', })
  },
  methods: {
    selectItemEvent(item) {
      this.selectedEvent = item
      let self = this
      let token = this.$store.state.application.keycloak.token
      this.showSpinner = true
      fetch("/api/eventParticipants/" + item.eventID, { headers: { 'Authorization': 'Bearer ' + token }} )
          .then(response => (response.status === 200) ? Promise.resolve(response) : Promise.reject(new Error(response.statusText)) )
          .then(response => response.json())
          .then(function (json) {
            self.selectedEventParticipants = json
            self.showSpinner = false
            self.showEventInfoDialog = true
          })
          .catch(function (error) {
            console.error('Request failed', error)
            self.showSpinner = false
          })
    },
    closeEventInfoDialog() {
      this.showEventInfoDialog = false
    },
    selectedEvents() {
      let selectedEvents = []
      this.activeEvents.forEach((value) => {
        if (value.selected === true && value.receivedSelectedState === true) {
          selectedEvents.push(value)
        }
      })
      return selectedEvents
    },
    showSelectedSessionsDisabled() {
      let selectedEventsCount = 0
      this.activeEvents.forEach((value) => {
        if (value.selected === true && value.receivedSelectedState === true) {
          selectedEventsCount++
        }
      })
      return selectedEventsCount <= 0
    },
    showSelectedSessions() {
      this.needShowViews = true
      this.needViewGridStudentSessions = true
      this.activeEvents.forEach((value) => {
        if (value.selected === true) {
          value.endpoints.forEach((endpoint) => {
            let remoteVideoID = value.studentID
            let endpointType = endpoint === "RECORDING_CAMERA_ENDPOINT" ? "VIEW_CAMERA_ENDPOINT" : "VIEW_SCREEN_ENDPOINT"
            let options = {
              localVideo: null,
              remoteVideo: remoteVideoID,
              endpointType: endpointType,
              studentID: value.studentID,
              endpoint: endpoint
            }
            this.$store.dispatch('application/openEndpoint', options)
          })
        }
      })
    },
    changeActiveEventSelected(event) {
      this.$nextTick(() => {
        let selectedRowElement = document.getElementById(event.target.id)
        let options = {action: "CHANGE_SELECTED_SESSION_STATE", studentID: event.target.id, state: selectedRowElement.checked}
        this.$store.dispatch('application/sendMessage', options)
      })
    },
    setCurrentSessionIndex(currentSessionIndex) { this.selectedSessionIndex = currentSessionIndex },
    toggleView(selectedSessionIndex) {
      this.needViewGridStudentSessions = !this.needViewGridStudentSessions
      this.selectedSessionIndex = selectedSessionIndex
    },
    closeView() {
      this.needShowViews = !this.needShowViews
      this.activeEvents.forEach((value) => {
        if (value.selected === true) {
          value.endpoints.forEach((endpoint) => {
            let endpointType = "VIEW_SCREEN_ENDPOINT"
            if (endpoint === "RECORDING_CAMERA_ENDPOINT") {
              endpointType = "VIEW_CAMERA_ENDPOINT"
            }
            this.$store.dispatch('application/closeEndpoint', endpointType)
          })
        }
      })
      this.$store.dispatch('application/clearActiveStreams')
    }
  }
}
</script>

<style scoped>
.img_logo {
  display: flex;
  align-self: center;
}
.dialogStudents {
      background: rgb(236, 239, 241);
}
</style>