import Vue from 'vue'
import App from './App.vue'
import store from './store'
import vuetify from './plugins/vuetify'
import Keycloak from "keycloak-js";

Vue.config.productionTip = false

let keycloak = Keycloak(document.keycloakInitOptions);
// Инициализировать Keycloak JS Adapter
keycloak.init({ onLoad: document.keycloakInitOptions.onLoad, checkLoginIframe: false }).then((auth) => {
    if (!auth) {
        // Если пользователь не авторизован - перезагрузить страницу
        window.location.reload()
    } else {
        console.log("application version: 0.0.1")
        new Vue({
            el: '#app',
            store,
            vuetify,
            render: h => h(App, { props: { keycloak: keycloak } })
        })
    }
    // Пытаемся обновить токен каждые 10 секунд
    setInterval(() => {
        // Обновляем токен, если срок его действия истекает в течении 50 секунд
        console.log("update token")
        keycloak.updateToken(50).catch(() => { console.error('Failed to refresh token') })
        /*.then((refreshed) => {
            if (refreshed) {
                console.log('Token refreshed' + refreshed)
            } else {
                console.log('Token not refreshed, valid for '
                        + Math.round(keycloak.tokenParsed.exp
                                + keycloak.timeSkew - new Date().getTime() / 1000) + ' seconds')
            }
        }).catch(() => {
            console.error('Failed to refresh token')
        })*/
    }, 10000)
}).catch(() => {
    console.error("Authenticated Failed")
})
