<template>
  <div>
    <v-alert color="blue-grey" class="py-0" dark dense prominent >
      <v-row>
        <v-col class="d-none d-sm-flex" lg="9" md="8" sm="7" cols="12">
          <v-btn class="ma-2" fab dark color="grey" disabled>
            <v-icon class="" large>mdi-school</v-icon>
          </v-btn>
          <span class="text-uppercase py-6">Выбор мероприятия</span>
        </v-col>
        <v-col class="mt-2" lg="3" md="4" sm="5" cols="12">
          <v-menu ref="menu" class="datamenu"
                  v-model="menu"
                  :close-on-content-click="false"
                  :return-value.sync="date"
                  min-width="auto" offset-y transition="scale-transition">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field v-model="date" format="DD/MM/YYYY"
                  v-bind="attrs"
                  v-on="on"
                  label="Дата мероприятия" prepend-icon="mdi-calendar" readonly />
            </template>
            <v-date-picker v-model="date" first-day-of-week="1" locale="ru-us"  :show-current="false"
                           no-title
                           scrollable>
              <v-spacer />
              <v-btn color="primary" text @click="menu = false">
                Отмена
              </v-btn>
              <v-btn color="primary" text @click="applyDate">
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
    </v-alert>
    <v-simple-table>
      <template v-slot:default>
        <thead>
        <tr>
          <th class="text-left">Мероприятие</th>
          <th class="text-left">Начинается</th>
          <th class="text-left">Заканчивается</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="item in events"
            :key="item.id" >
          <td>{{ item.description }}</td>
          <td>{{ formatDate(item.startDate) }}</td>
          <td>{{ formatDate(item.endDate) }}</td>
        </tr>
        </tbody>
      </template>
    </v-simple-table>
    <v-dialog v-model="showSpinner" fullscreen>
      <v-progress-circular color="primary" indeterminate/>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "PlannedEvents",
  data: () => ({
    date: document.eventsDate,
    menu: false,
    events: [],
    showSpinner: false
  }),
  mounted() { this.applyDate() },
  methods: {
    applyDate() {
      this.showSpinner = true;
      let oldEventsDate = this.date
      this.$refs.menu.save(this.date)
      let self = this;
      let token = this.$store.state.application.keycloak.token
      fetch("/api/plannedEvents?date=" + this.date, { headers: { 'Authorization': 'Bearer ' + token }} )
          .then(response => (response.status === 200) ? Promise.resolve(response) : Promise.reject(new Error(response.statusText)) )
          .then(response => response.json())
          .then(function (json) {
            self.events = json
            self.showSpinner = false
          })
          .catch(function (error) {
            console.error('Request failed', error)
            self.eventsDate = oldEventsDate
            self.$refs.menu.save(oldEventsDate)
            self.showSpinner = false
          })
    },
    formatDate(stringDate) {
      let date = new Date(stringDate)
      return (date.getDate() > 9 ? date.getDate() : ("0" + date.getDate())) + "-" +
          (date.getMonth() + 1 > 9 ? date.getMonth() + 1 : ("0" + (date.getMonth() + 1))) + "-" +
          date.getFullYear() + " " +
          (date.getHours() > 9 ? date.getHours() : ("0" + date.getHours())) + ":" +
          (date.getMinutes() > 9 ? date.getMinutes() : ("0" + date.getMinutes()))
    }
  }
}
</script>

<style scoped>

</style>