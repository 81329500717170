<template>
  <div>
    <v-alert color="blue-grey" class="py-0" dark dense prominent >
      <v-row>
        <v-col class="d-none d-sm-flex" lg="9" md="8" sm="7" cols="12">
          <v-btn class="ma-2" fab dark color="grey" disabled>
            <v-icon class="" large>mdi-school</v-icon>
          </v-btn>
          <span class="text-uppercase py-6">Выбор мероприятия</span>
        </v-col>
        <v-col class="mt-2" lg="3" md="4" sm="5" cols="12">
          <v-menu ref="menu" class="datamenu" v-model="menu"
                  :close-on-content-click="false"
                  :return-value.sync="date"
                  min-width="auto"
                  offset-y
                  transition="scale-transition">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                  v-model="date"  format="DD/MM/YYYY"
                  v-bind="attrs"
                  v-on="on" label="Дата мероприятия" prepend-icon="mdi-calendar" readonly />
            </template>
            <v-date-picker v-model="date" first-day-of-week="1" locale="ru-us"  :show-current="false"
                           no-title scrollable >
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="menu = false">
                Отмена
              </v-btn>
              <v-btn color="primary" text @click="applyDate"> <!-- TODO а нужно-ли нам менять дату для студента? -->
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
    </v-alert>
    <v-simple-table>
      <template v-slot:default>
        <thead>
        <tr>
          <th class="text-left">Мероприятие</th>
          <th class="text-left">Начинается</th>
          <th class="text-left">Заканчивается</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="item in events" :key="item.id" @click="onSelectEvent(item)">
          <td>{{ item.description }}</td>
          <td>{{ formatDate(item.startDate) }}</td>
          <td>{{ formatDate(item.endDate) }}</td>
        </tr>
        </tbody>
      </template>
    </v-simple-table>
    <iframe id="selectedEventRecording"
            :src="recordingURL()"
            @load="afterPageLoad()"
            style="display: none"></iframe>
    <v-dialog v-model="dialog" fullscreen>
      <v-btn color="primary" dark @click="closeDialog">Вернуться к списку мероприятий</v-btn>
      <v-card height="100%">
        <iframe :src="selectedEvent.resourceUrl" style="width:100%;height:100%;"></iframe>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showSpinner" fullscreen>
      <v-progress-circular color="primary" indeterminate/>
    </v-dialog>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "PlannedEvents",
  data: () => ({
    date: document.eventsDate,
    menu: false,
    events: [],
    showSpinner: false,
    dialog: false,
    selectedEvent: {},
    iframeLoaded: false,
    recording: "/recording/"
  }),
  mounted() {
    this.applyDate()
    let self = this
    window.onmessage = function(event) {
      /*if (event.data === 'iframeLoaded') {
        self.iframeLoaded = true
        console.log("iframeLoaded")
      }*/
      if (event.data === 'peerConnectionConnectionStateFailed') {
        console.log("peerConnectionConnectionStateFailed")
        let iframe = document.getElementById("selectedEventRecording")
        if (iframe === null) { return }
        setTimeout(function () {
          console.log("timeout self.recordingURL() - 0")
          if (self.iframeLoaded) { return }
          self.iframeLoaded = false
          iframe.src = self.recordingURL()
          self.setSelectedEventRecording()
          console.log("timeout self.recordingURL() - 1")
        }, 20000)
      }
    }
  },
  computed: {
    ...mapGetters('application', {
      isTransportActive: 'isTransportActive',
    }),
  },
  methods: {
    afterPageLoad() {
      /*let iframe = document.getElementById("selectedEventRecording")
      if (iframe === null) { return }
      let token = this.$store.state.application.keycloak.token
      iframe.contentWindow.postMessage(token, '*')*/
    },
    recordingURL() {
      return this.recording + this.$store.state.application.actorID
    },
    setSelectedEventRecording() {
      let iframe = document.getElementById("selectedEventRecording")
      if (iframe === null) { return }
      iframe.contentWindow.document.connection = this.$store.state.application.transportConnection
      iframe.contentWindow.document.camera = this.$store.state.application.currentCamera.deviceId
      iframe.contentWindow.document.actorID = this.$store.state.application.actorID
      let eventOptions = {
        eventID: this.selectedEvent.id,
        eventName: this.selectedEvent.description,
        eventStartDate: this.selectedEvent.startDate,
        eventEndDate: this.selectedEvent.endDate,
        actorFullName: this.$store.state.application.actorFullName,
        groupName: this.selectedEvent.groupName
      }
      let message = { type: "openRecordingEndpoints", eventOptions: eventOptions, iceServers: this.$store.state.application.iceServers }
      iframe.contentWindow.postMessage(JSON.stringify(message), "*")
      iframe.contentWindow.document.startRecording()
    },
    applyDate() {
      this.showSpinner = true;
      let oldEventsDate = this.date
      this.$refs.menu.save(this.date)
      let self = this;
      let token = this.$store.state.application.keycloak.token
      fetch("/api/plannedEvents?date=" + this.date, { headers: { 'Authorization': 'Bearer ' + token }} )
          .then(response => (response.status === 200) ? Promise.resolve(response) : Promise.reject(new Error(response.statusText)) )
          .then(response => response.json())
          .then(function (json) {
            self.events = json
            self.showSpinner = false
          })
          .catch(function (error) {
            console.error('Request failed', error)
            self.eventsDate = oldEventsDate
            self.$refs.menu.save(oldEventsDate)
            self.showSpinner = false
          })
    },
    onSelectEvent(eventItem) {
      let currentDate = new Date()
      let startDate = new Date(eventItem.startDate)
      let endDate = new Date(eventItem.endDate)

      if (!(currentDate >= startDate && currentDate <= endDate)) { return }

      if ("resourceUrl" in eventItem && eventItem.resourceUrl.length > 0) {
        this.selectedEvent = eventItem
        //this.$store.dispatch('application/openRecordingEndpoints', eventOptions)

        this.setSelectedEventRecording()

        this.dialog = this.isTransportActive//todo
        return
      }
      console.error("NOT found resourceUrl ", eventItem)
    },
    closeDialog() {
      /*this.$store.dispatch('application/closeEndpoint', "RECORDING_CAMERA_ENDPOINT")
      this.$store.dispatch('application/closeEndpoint', "RECORDING_SCREEN_ENDPOINT")*/
      this.dialog = false
      let iframe = document.getElementById("selectedEventRecording")
      if (iframe === null) { return }
      iframe.src = "/invalidPage"
    },
    formatDate(stringDate) {
      let date = new Date(stringDate)
      return (date.getDate() > 9 ? date.getDate() : ("0" + date.getDate())) + "-" +
          (date.getMonth() + 1 > 9 ? date.getMonth() + 1 : ("0" + (date.getMonth() + 1))) + "-" +
          date.getFullYear() + " " +
          (date.getHours() > 9 ? date.getHours() : ("0" + date.getHours())) + ":" +
          (date.getMinutes() > 9 ? date.getMinutes() : ("0" + date.getMinutes()))
    }
  }
}
</script>

<style scoped>

</style>