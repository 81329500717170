import Vue from 'vue'
import Vuex from 'vuex'
import applicationUI from './modules/applicationUI'
import application from './modules/application'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    applicationUI,
    application,
  }
})