<template>
    <v-card>
      <!--div class="preloader hidden" id="preloader">
        <div><v-icon class="preloader__image">mdi-timer-sand-complete</v-icon></div>
      </div-->
      <div class="preloader hidden" id="preloader">
            <div class="preloader__row">
                <div class="preloader__item"></div>
                <div class="preloader__item"></div>
            </div>
        </div>
      <div align="right">
        <v-btn icon  @click="closeDialog">
          <v-icon >mdi-close</v-icon>
        </v-btn>
      </div>

      <div class="container-fluid px-5" style="display: flex; justify-content: center; padding-bottom: 50px;">
        <div class="timeLineRow"  >
          <div class="finish">
            <div class="icon">
              <v-icon>mdi-play</v-icon>
            </div>
          </div>
          <div class="vertLine"></div>

          <div v-for="(item, index) in itemsDateTime()" v-bind:key="index" class="blockVideo cursor-pointer" @click="selectItem(index)">
            <!--div class="icon small"> <v-icon class="cursor-pointer" @click="selectItem(index)">mdi-play</v-icon> </div-->
            <div class="date">{{item.date}}</div>
            <div class="time">{{item.time}}</div>
          </div>

          <div class="vertLine"></div>

          <div class="finish">
            <div class="icon">
              <v-icon>mdi-flag-checkered</v-icon>
            </div>
          </div>
        </div>
      </div>

      <div class="container-fluid pa-5" style="display:flex; justify-content: center;">        

        <div class="savedVideoBox mr-3">
          <video id="camera" ref="camera"
                 :src="cameraSource"
                 autoplay
                 controls height=400
                 width="100%"
                 @pause="pauseScreen"
                 @play="playScreen"
                 @seeked="syncScreen"
                 @ended="endedCamera"                
                 @canplay="endPreloader"
                 />
                 
        </div>
        <div class="savedVideoBox ml-3">
          <video id="screen"
                 ref="screen"
                 :src="screenSource"
                 height=400
                 width="100%"                
                 />
        </div>
      </div>     
    </v-card>
</template>
<script>
export default {
  name: "ViewSavedStreams",
  props: {
    selectedEvent: Object,
    cameraSource: null,
    screenSource: null
  },
  data: () => ({
    videoIndex: 0,
  }), 
  methods: {    
    endPreloader() {
      console.log('endPreloader');
      let elem = document.getElementById("preloader");
      //elem.classList.add("hidden");
      elem.style.display ="none";
      console.log('endPreloader classList=' + elem.classList);
    },
    closeDialog() {
      this.$refs.camera.pause()
      this.$emit('closeViewSavedStreamsDialog')
    },
    playScreen() {
      this.$refs.screen.play()
    },
    pauseScreen() {
      this.$refs.screen.pause()
    },
    syncScreen() {
      this.$refs.screen.currentTime = this.$refs.camera.currentTime
    },
    setCameraSource() { this.dataURL(this.createURL("RECORDING_CAMERA_ENDPOINT")).then(data => this.$refs.camera.src = data) },
    setScreenSource() { this.dataURL(this.createURL("RECORDING_SCREEN_ENDPOINT")).then(data => this.$refs.screen.src = data) },
    async dataURL(url) {
      const response = await fetch(url, { headers: { 'Authorization': 'Bearer ' + this.$store.state.application.keycloak.token }})
      const blob = await response.blob()
      return URL.createObjectURL(blob)
    },
    createURL(endpointType) {
      return `api/stream/${endpointType}/${this.selectedEvent.sessionID}/${this.selectedEvent.eventID}/${this.selectedEvent.studentID}/${this.selectedEvent.recordingStartTime[this.videoIndex].replace(" ", "_")}`
    },
    endedCamera() {
      if (this.selectedEvent.recordingStartTime.length <= 1) { return }
      if (this.videoIndex >= this.selectedEvent.recordingStartTime.length) { return }
      this.videoIndex++
      this.setCameraSource()
      this.setScreenSource()
    },
    itemsDateTime() {
      if (this.selectedEvent === null) { return [] }
      if (this.selectedEvent.recordingStartTime === null) { return [] }

      if (this.selectedEvent.recordingStartTime.length === 1) {
        return this.itemDateTime(this.selectedEvent.recordingStartTime[0])
      }

      let items = []
      for (let i = 0; i < this.selectedEvent.recordingStartTime.length; i++) {
        items.push(this.itemDateTime(this.selectedEvent.recordingStartTime[i]))
      }

      return items
    },
    itemDateTime(recordingStartTime) {
      let data = recordingStartTime.split(' ')
      if (data.length === 1) {
        console.error("invalid recordingStartTime: ", recordingStartTime)
        return {
          date: data[0],
          time: ''
        }
      }
      if (data.length > 2) {
        console.error("invalid recordingStartTime: ", recordingStartTime)
        return {
          date: data[0],
          time: data[1]
        }
      }
      return {
        date: data[0],
        time: data[1]
      }
    },
    selectItem(index) {
      this.videoIndex = index
      this.setCameraSource()
      this.setScreenSource()
      let elem = document.getElementById("preloader");
      elem.style.display ="block";
    }
  }
}
</script>


<style>			
  .timeLineRow {
    display: flex;
    align-items: end;
  }
  .blockVideo {
    background: linear-gradient(45deg, rgb(0, 108, 180), rgb(86, 181, 184));
    width: 100px;
    padding: 5px;
    border-radius: 2px;
    height: 55px;
    margin-left: 1px;
    margin-right: 1px;				
  }
  .blockSuccess{
    background: linear-gradient(45deg, rgb(40, 167, 69), rgb(86, 181, 184));
    width: 100px;
    padding: 5px;
    border-radius: 2px;
    height: 60px;
    margin-left: 1px;
    margin-right: 1px;				
  }
  .vertLine {
    border-right: 1px dashed grey;
    height: 55px;
  }
  .date {
    color: #fff;
    font-size: 10px;
  }
  .time {
    color: #fff;
    font-size: 18px;
  }
  .icon {
    margin-left: 2px;
  }
  .finish {
      display: flex;
      align-items: center;
      height: 55px;
  }
  .small {
    font-size: 12px;
  }.cursor-pointer {
     cursor: pointer;
   }
   .preloader {
      position: fixed;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      background: #e0e0e0;
      opacity: .8;
      z-index: 1001;
    }
    .preloader__row {
      position: relative;
      top: 50%;
      left: 50%;
      width: 70px;
      height: 70px;
      margin-top: -35px;
      margin-left: -35px;
      text-align: center;
      animation: preloader-rotate 2s infinite linear;
    }
    .preloader__item {
      position: absolute;
      display: inline-block;
      top: 0;
      background-color: #337ab7;
      border-radius: 100%;
      width: 35px;
      height: 35px;
      animation: preloader-bounce 2s infinite ease-in-out;
    }
    .preloader__item:last-child {
      top: auto;
      bottom: 0;
      animation-delay: -1s;
    }
    @keyframes preloader-rotate {
      100% {
        transform: rotate(360deg);
      }
    }
    @keyframes preloader-bounce {
      0%,
      100% {
        transform: scale(0);
      }
      50% {
        transform: scale(1);
      }
    }

.hidden {
  display: none;
}
</style>