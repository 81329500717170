import { render, staticRenderFns } from "./ViewCarouselStudentSessions.vue?vue&type=template&id=5f7a6754&scoped=true"
import script from "./ViewCarouselStudentSessions.vue?vue&type=script&lang=js"
export * from "./ViewCarouselStudentSessions.vue?vue&type=script&lang=js"
import style0 from "./ViewCarouselStudentSessions.vue?vue&type=style&index=0&id=5f7a6754&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5f7a6754",
  null
  
)

export default component.exports