import { render, staticRenderFns } from "./PlannedEvents.vue?vue&type=template&id=2ffa98cb&scoped=true"
import script from "./PlannedEvents.vue?vue&type=script&lang=js"
export * from "./PlannedEvents.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2ffa98cb",
  null
  
)

export default component.exports