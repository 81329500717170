<template>
  <v-container>
    <v-row>
      <v-col md="2" sm="2" cols="12"><small class="text-uppercase letter-spacing">Камера</small></v-col>
      <v-col md="4" sm="4" cols="12">
        <v-select v-model="currentCamera" style="margin-top: -20px;"
                  :disabled="checkStarted"
                  :items="cameraDevices"
                  item-text="label"
                  item-value="deviceId" />
      </v-col>
      <v-col md="3" sm="2" cols="6" align="center">
        <v-btn :disabled="checkStarted"
               small
               color="green"
               @click="onStart"> <v-icon color="#ffffff">mdi-arrow-right-drop-circle-outline </v-icon>
          <span class="white--text"> Start</span>
        </v-btn>
      </v-col>
      <v-col md="3" sm="2" cols="6" align="center">
        <v-btn :disabled="!checkStarted"
               small
               color="red"
               @click="onStop"> <v-icon color="#ffffff">mdi-stop</v-icon>
          <span class="white--text">Stop</span>
        </v-btn>
      </v-col>
    </v-row>
    <v-row style="margin-top: -20px;">
      <v-col md="7" sm="7" cols="12">
        <video id="localVideo"
               ref="localVideo"
               autoplay muted height=190 width="100%" />
      </v-col>
      <v-col md="5" sm="5" cols="12">
        <small class="text-uppercase letter-spacing">Эхо</small>
        <video id="remoteVideo"
               ref="remoteVideo"
               @canplay="cameraCanplay"
               autoplay muted height=100 width="100%" />
      </v-col>
    </v-row>
    <v-row>
      <v-col><small class="text-uppercase letter-spacing">Экран</small></v-col>
    </v-row>
    <v-row>
      <v-col md="7" sm="7" cols="12">
        <video id="localScreenVideo"
               ref="localScreenVideo"
               autoplay muted height=190 width="100%" />
      </v-col>
      <v-col md="5" sm="5" cols="12">
        <small class="text-uppercase letter-spacing">Эхо</small>
        <video id="remoteScreenVideo"
               ref="remoteScreenVideo"
               @canplay="screenCanplay"
               autoplay height=100 width="100%" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
  name: "EchoEndpointsView",
  data: () => ({
    checkStarted: false
  }),
  computed: {
    ...mapGetters('application', {
      cameraDevices: 'cameraDevices'
    }),
    currentCamera: {
      get: function () {
        return this.$store.state.application.currentCamera
      },
      set: function (currentCamera) {
        return this.$store.dispatch('application/setCurrentCamera', currentCamera)
      }
    }
  },
  methods: {
    onStart() {
      this.checkStarted = true
      this.$emit('check-echo-started')
      let cameraOptions = {
        endpointType: "ECHO_CAMERA_ENDPOINT",
        localVideo: document.getElementById("localVideo"),
        remoteVideo: document.getElementById("remoteVideo")
      }
      this.$store.dispatch('application/openEndpoint', cameraOptions)
      let screenOptions = {
        endpointType: "ECHO_SCREEN_ENDPOINT",
        localVideo: document.getElementById("localScreenVideo"),
        remoteVideo: document.getElementById("remoteScreenVideo")
      }
      this.$store.dispatch('application/openEndpoint', screenOptions)
    },
    onStop() {
      this.checkStarted = false
      this.$store.state.application.cameraAvailable = false
      this.$store.state.application.screenAvailable = false
      this.$store.state.application.cameraStreamAvailable = false
      this.$store.state.application.screenStreamAvailable = false
      this.$store.dispatch('application/closeEndpoint', "ECHO_CAMERA_ENDPOINT")
      this.$store.dispatch('application/closeEndpoint', "ECHO_SCREEN_ENDPOINT")
    },
    cameraCanplay() {
      this.$store.state.application.cameraStreamAvailable = true
    },
    screenCanplay() {
      this.$store.state.application.screenStreamAvailable = true
    }
  }
}
</script>

<style scoped>
.letter-spacing {
  letter-spacing: .1rem;
}
</style>