import kurentoUtils from "kurento-utils";

const createConnection = function (state) {
    console.log("Trying to open socket connection")
    let timeout = 10000
    let connection = new WebSocket(document.websocketSchema + "://" + location.host + "/transportHandler")
    connection.onopen = function () {
        state.transportReady = true
        connection.send(JSON.stringify({ action: "ONLINE", token: state.keycloak.token}))
    }
    connection.onmessage = function (event) {
        let parsedMessage = JSON.parse(event.data)
        let findActiveEventIndexByStudentID = function (id) {
            for (let i = 0; i < state.activeEvents.length; i++) {
                if (state.activeEvents[i].studentID === id) { return i }
            }
            return -1
        }
        if (("action" in parsedMessage)) {
            switch (parsedMessage.action) {
                case 'MediaFlowState_NOT_FLOWING': {
                    let eventIndex = findActiveEventIndexByStudentID(parsedMessage.studentID)
                    if (eventIndex !== -1) {
                        state.activeEvents[eventIndex]["NOT_FLOWING"][parsedMessage["endpointType"]][parsedMessage["mediaType"]] = true
                        state.activeEvents[eventIndex]["proctorAlreadySeenAttention"] = false
                    }
                    break
                }
                case 'MediaFlowState_FLOWING': {
                    let eventIndex = findActiveEventIndexByStudentID(parsedMessage.studentID)
                    if (eventIndex !== -1) {
                        state.activeEvents[eventIndex]["NOT_FLOWING"][parsedMessage["endpointType"]][parsedMessage["mediaType"]] = false
                    }
                    break
                }
                case 'disconnectedWS': {
                    let eventIndex = findActiveEventIndexByStudentID(parsedMessage.studentID)
                    if (eventIndex !== -1) {
                        state.activeEvents[eventIndex].disconnectedWS = true
                        state.activeEvents[eventIndex]["proctorAlreadySeenAttention"] = false
                    }
                    break
                }
                case 'connectedWS': {
                    let eventIndex = findActiveEventIndexByStudentID(parsedMessage.studentID)
                    if (eventIndex !== -1) { state.activeEvents[eventIndex].disconnectedWS = false }
                    break
                }
                case 'addStudentActiveSession': {
                    parsedMessage["selected"] = false
                    parsedMessage["NOT_FLOWING"] = {
                        RECORDING_CAMERA_ENDPOINT: {
                            AUDIO: false,
                            VIDEO: false,
                        },
                        RECORDING_SCREEN_ENDPOINT: {
                            AUDIO: false,
                            VIDEO: false,
                        }
                    }
                    parsedMessage["disconnectedWS"] = false
                    parsedMessage["receivedSelectedState"] = false
                    parsedMessage["proctorAlreadySeenAttention"] = true
                    let studentInfoURL = "/api/users/" + parsedMessage.studentID + "/studentInfo"
                    fetch(studentInfoURL, { headers: { 'Authorization': 'Bearer ' + state.keycloak.token }})
                            .then(response => (response.status === 200) ? Promise.resolve(response) : Promise.reject(new Error(response.statusText)) )
                            .then(response => response.json())
                            .then(json => {
                                parsedMessage["actorImage"] = json.image
                                parsedMessage["actorDivisionLevel"] = json.divisionLevel
                                while (findActiveEventIndexByStudentID(parsedMessage.studentID) !== -1) {
                                    state.activeEvents.splice(findActiveEventIndexByStudentID(parsedMessage.studentID), 1)
                                }
                                state.activeEvents.push(parsedMessage)
                                state.activeEvents.sort(function (a, b) { return a.eventName.localeCompare(b.eventName) || a.actorName.localeCompare(b.actorName) })
                            })
                            .catch(error => console.error('iceServers request failed: ', error))
                    break
                }
                case 'changeStateStudentActiveSession': {
                    let eventIndex = findActiveEventIndexByStudentID(parsedMessage.studentID)
                    if (eventIndex !== -1) {
                        state.activeEvents[eventIndex].selected = parsedMessage.selected
                        state.activeEvents[eventIndex].receivedSelectedState = parsedMessage.selected
                    }
                    break
                }
                case 'removeStudentActiveSession': {
                    let eventIndex = findActiveEventIndexByStudentID(parsedMessage.studentID)
                    while (eventIndex !== -1) {
                        state.activeEvents.splice(eventIndex, 1)
                        eventIndex = findActiveEventIndexByStudentID(parsedMessage.studentID)
                    }
                    state.activeEvents.sort(function (a, b) { return a.eventName.localeCompare(b.eventName) || a.actorName.localeCompare(b.actorName) })
                    break
                }
                case 'setVideoPosition': {
                    state.playerVideoPosition = parsedMessage.position
                }
            }
            if (!("endpointType" in parsedMessage)) { return }
        }

        if (("endpointType" in parsedMessage)) {
            const findStateEndpoint = function (endpointType, actorID) {
                for (let i = 0; i < state.endpoints.length; i++) {
                    if (state.endpoints[i].endpointType === endpointType && state.endpoints[i].actorID === actorID) {
                        return state.endpoints[i]
                    }
                }
                return null
            }
            switch (parsedMessage.action) {
                case 'iceCandidate':
                    if (parsedMessage.endpointType === "RECORDING_CAMERA_ENDPOINT" || parsedMessage.endpointType === "RECORDING_SCREEN_ENDPOINT") {
                        let iframe = document.getElementById("selectedEventRecording")
                        console.log("iframe: ", iframe)
                        if (iframe !== null) {
                            let message = { type: "iceCandidate", messageWS: parsedMessage }
                            iframe.contentWindow.postMessage(JSON.stringify(message), "*")
                        }
                    } else {
                        let id = "studentID" in parsedMessage ? parsedMessage.studentID : state.actorID
                        if (parsedMessage.endpointType.includes("PLAY_")) { id = state.actorID }
                        let stateEndpoint = findStateEndpoint(parsedMessage.endpointType, id)
                        if (stateEndpoint === null) {
                            return
                        }
                        const endpoint = stateEndpoint.endpoint
                        if (endpoint === null) {
                            return
                        }
                        endpoint.addIceCandidate(parsedMessage.candidate, function (error) {
                            if (error) {
                                return console.error('Error adding candidate: ' + error)
                            }
                        })
                    }
                    break
                case 'answer':
                    if (parsedMessage.endpointType === "RECORDING_CAMERA_ENDPOINT" || parsedMessage.endpointType === "RECORDING_SCREEN_ENDPOINT") {
                        let iframe = document.getElementById("selectedEventRecording")
                        console.log("iframe: ", iframe)
                        if (iframe !== null) {
                            let message = { type: "answer", messageWS: parsedMessage }
                            iframe.contentWindow.postMessage(JSON.stringify(message), "*")
                        }
                    } else {
                        let id = "studentID" in parsedMessage ? parsedMessage.studentID : state.actorID
                        if (parsedMessage.endpointType.includes("PLAY_")) { id = state.actorID }
                        let stateEndpoint = findStateEndpoint(parsedMessage.endpointType, id)
                        if (stateEndpoint === null) {
                            return
                        }
                        const endpoint = stateEndpoint.endpoint
                        if (endpoint === null) {
                            return
                        }
                        endpoint.processAnswer(parsedMessage.answer, function (error) {
                            if (error) {
                                return console.error(error)
                            }
                        })
                    }
                    break
                case 'setVideoInfo':
                    // eslint-disable-next-line no-case-declarations
                    let id = "studentID" in parsedMessage ? parsedMessage.studentID : state.actorID
                    if (parsedMessage.endpointType.includes("PLAY_")) { id = state.actorID }
                    // eslint-disable-next-line no-case-declarations
                    let stateEndpoint = findStateEndpoint(parsedMessage.endpointType, id)
                    if (stateEndpoint === null) {
                        return
                    }
                    stateEndpoint.videoInfo = parsedMessage.videoInfo
                    break
                default:
                    console.error('Unrecognized message', parsedMessage)
            }
        }
    }
    connection.onerror = function (event) {
        console.error('transport error: ' + JSON.stringify(new Date().toJSON()), event)
    }
    connection.onclose = function (event) {
        console.error('transport close:', event)
        state.transportReady = false
        if (state.needReconnect && connection.readyState === WebSocket.CLOSED) {
            setTimeout(function () {
                createConnection(state)
            }, timeout)
        }
    }
    state.transportConnection = connection
}

const state = () => ({
    keycloak: null,
    actorFullName: "",
    actorID: "",
    mediaDevices: {},
    cameraDevices: [],
    currentCamera: null,
    showSpinner: false,
    transportConnection: null,
    needReconnect: true,
    iceServers: [],
    cameraID: null,
    activeEvents: [],
    endpoints: [],
    activeStreams: [],
    transportReady: false,
    cameraAvailable: false,
    screenAvailable: false,
    cameraStreamAvailable: false,
    screenStreamAvailable: false,
    playerVideoPosition: 0,
    reconnectRTC: false
})

const getters = {
    activeEvents: (state) => { return state.activeEvents },
    cameraDevices: (state) => { return state.cameraDevices },
    currentCamera: (state) => { return state.currentCamera },
    showSpinner: (state) => { return state.showSpinner },
    activeStreams: (state) => { return state.activeStreams },
    isTransportActive: (state) => {
        return state.transportConnection !== null && state.transportConnection.readyState === WebSocket.OPEN
    }
}

const actions = {
    setCurrentCamera({state}, camera) {
        state.currentCamera = camera
    },

    openConnection({state}) {
        if ("activeEvents" in document && document.activeEvents.length > 0) {
            document.activeEvents.forEach((value) => { state.activeEvents.push(value) } )
        }
        createConnection(state)
    },

    closeConnection({state}) {
        state.needReconnect = false
        if (state.transportConnection.readyState === WebSocket.OPEN) { state.transportConnection.send("{ \"action\": \"OFFLINE\" }") }
        state.transportConnection.close()
    },

    sendMessage({state}, transportMessage) {
        if (state.transportConnection.readyState === WebSocket.OPEN) { state.transportConnection.send(JSON.stringify(transportMessage)) }
    },

    setupCameraDevices({state}) {
        const legacyGetUserMediaSupport = function () {
            return constraints => {
                let getUserMedia =
                    navigator.getUserMedia ||
                    navigator.webkitGetUserMedia ||
                    navigator.mozGetUserMedia ||
                    navigator.msGetUserMedia ||
                    navigator.oGetUserMedia;
                if (!getUserMedia) {
                    return Promise.reject(new Error("getUserMedia is not implemented in this browser"))
                }
                return new Promise(function (resolve, reject) {
                    getUserMedia.call(navigator, constraints, resolve, reject)
                })
            }
        }
        state.mediaDevices = navigator.mediaDevices === undefined ? {} : navigator.mediaDevices
        if (state.mediaDevices.getUserMedia === undefined) {
            state.mediaDevices.getUserMedia = legacyGetUserMediaSupport()
        }
        state.mediaDevices
            .enumerateDevices()
            .then(deviceInfos => {
                for (let i = 0; i !== deviceInfos.length; ++i) {
                    console.log(deviceInfos[i])
                    let deviceInfo = deviceInfos[i];
                    if (deviceInfo.kind === "videoinput") {
                        state.cameraDevices.push(deviceInfo)
                        //state.currentCamera = deviceInfo
                        state.currentCamera = deviceInfo.label || `camera ${i + 1}`
                    }
                }
                if (state.currentCamera === null) {
                    console.error("camera not found")
                } else {
                    console.error("camera found")
                    console.log(state.currentCamera)
                }
            })
            .catch(error => console.error(error))
    },

    loadIceServers({state, commit}) {
        fetch("/api/iceServers", { headers: { 'Authorization': 'Bearer ' + state.keycloak.token }})
            .then(response => (response.status === 200) ? Promise.resolve(response) : Promise.reject(new Error(response.statusText)) )
            .then(response => response.json())
            .then(json => commit('setIceServers', (Array.isArray(json) ? json : [])))
            .catch(error => console.error('iceServers request failed: ', error))
    },

    setCameraID({commit}, cameraID) {
        commit('setCameraID', cameraID)
    },

    closeEndpoint({state}, endpointType) {
        for (let i = 0; i < state.endpoints.length; i++) {
            if (state.endpoints[i].endpointType === endpointType && (endpointType.includes("VIEW_") || state.endpoints[i].actorID === state.actorID)) {
                if (("stream" in state.endpoints[i]) && state.endpoints[i].stream !== null) {
                    let tracks = state.endpoints[i].stream.getTracks();
                    tracks.forEach(track => {
                        track.stop()
                    })
                    state.endpoints[i].stream = null
                }
                if (state.endpoints[i].endpoint.localVideo && state.endpoints[i].endpoint.localVideo !== null && "srcObject" in state.endpoints[i].endpoint.localVideo) {
                    let tracks = state.endpoints[i].endpoint.localVideo.srcObject.getTracks();
                    tracks.forEach(track => {
                        track.stop()
                    })
                    state.endpoints[i].endpoint.localVideo.srcObject = null
                }
                state.endpoints[i].endpoint.dispose()
                state.endpoints[i] = null
                state.endpoints.splice(i, 1)
            }
        }
        let message = {
            action: "CLOSE_ENDPOINT",
            endpoint: endpointType
        }
        if (state.transportConnection.readyState === WebSocket.OPEN) { state.transportConnection.send(JSON.stringify(message)) }
    },

    openEndpoint({state}, endpointOptions) {
        const iceCandidateFunction = function (candidate) {
            let message = {
                action: 'ICE_CANDIDATE',
                endpoint: endpointOptions.endpointType,
                candidate: candidate,
                options: ("options" in endpointOptions) ? endpointOptions.options : null
            }
            if (state.transportConnection.readyState === WebSocket.OPEN) { state.transportConnection.send(JSON.stringify(message)) }
        }

        const offerFunction = function (error, offer) {
            if (error) { return console.error('Error generating the offer', error) }
            const data = ((offer != null && typeof offer == 'object') && ("sdp" in offer)) ? offer.sdp : offer
            let offerMessage = {
                action: 'OPEN_ENDPOINT',
                endpoint: endpointOptions.endpointType,
                offer: data,
                options: ("options" in endpointOptions) ? endpointOptions.options : null
            }
            console.log("offerMessage: ", offerMessage)
            if (state.transportConnection.readyState === WebSocket.OPEN) { state.transportConnection.send(JSON.stringify(offerMessage)) }
        }

        const createCameraEndpoint = function () {
            let constraints = {video: {deviceId: {exact: state.currentCamera.deviceId}}, audio: true}
            const loadCameraStream = function (stream) {
                showStream(stream, endpointOptions)
                const options = {
                    localVideo: endpointOptions.localVideo,
                    peerConnection: createPeerConnection(state, offerFunction),
                    remoteVideo: endpointOptions.remoteVideo,
                    mediaConstraints: {
                        audio: true,
                        video: true
                    },
                    onicecandidate: iceCandidateFunction
                }
                const endpoint = new kurentoUtils.WebRtcPeer.WebRtcPeerSendrecv(options,
                        function (error) {
                            if (error) {
                                return console.error(error)
                            }
                            endpoint.generateOffer(offerFunction);
                        })
                state.endpoints.push({ actorID: state.actorID, endpointType: endpointOptions.endpointType, endpoint: endpoint })
            }
            state.mediaDevices
                .getUserMedia(constraints)
                .then(stream => { state.cameraAvailable = true; loadCameraStream(stream); })
                .catch(error => console.error(error))
        }

        const createScreenEndpoint = function () {
            let constraints = {video: {cursor: "always"}, audio: false}
            const loadScreenStream = function (stream) {
                showStream(stream, endpointOptions)
                const options = {
                    localVideo: null,
                    videoStream: stream,
                    peerConnection: createPeerConnection(state, offerFunction),
                    remoteVideo: endpointOptions.remoteVideo,
                    mediaConstraints: {
                        audio: false,
                        video: true
                    },
                    onicecandidate: iceCandidateFunction
                }

                const endpoint = new kurentoUtils.WebRtcPeer.WebRtcPeerSendrecv(options,
                        function (error) {
                            if (error) {
                                return console.error(error)
                            }
                            endpoint.generateOffer(offerFunction);
                        })
                state.endpoints.push({ actorID: state.actorID, endpointType: endpointOptions.endpointType, endpoint: endpoint })
            }
            navigator.mediaDevices
                .getDisplayMedia(constraints)
                .then(stream => { state.screenAvailable = true; loadScreenStream(stream); })
                .catch(error => console.error(error))
        }

        const viewerOfferFunction = function (error, offer) {
            if (error) {
                return console.error('Error generating the offer', error)
            }
            let offerMessage = {
                studentID: endpointOptions.studentID,
                action: 'VIEW_STUDENT_SESSION',
                endpointType: endpointOptions.endpointType,
                endpoint: endpointOptions.endpoint,
                offer: offer
            }
            if (state.transportConnection.readyState === WebSocket.OPEN) { state.transportConnection.send(JSON.stringify(offerMessage)) }
        }

        const createViewerEndpoint = function () {
            const peerConnection = createPeerConnection(state, viewerOfferFunction)
            peerConnection.ontrack = function(event) {
                state.activeStreams.push({
                    id: endpointOptions.endpointType + "-" + endpointOptions.remoteVideo,
                    stream: event.streams[0]
                })
                //TODO point to insert video processing?
            }
            const options = {
                peerConnection: peerConnection,
                onicecandidate: iceCandidateFunction
            }
            const endpoint = new kurentoUtils.WebRtcPeer.WebRtcPeerRecvonly(options,
                function (error) {
                    if (error) {
                        return console.error(error)
                    }
                    endpoint.generateOffer(viewerOfferFunction);
                })
            state.endpoints.push({ actorID: endpointOptions.studentID, endpointType: endpointOptions.endpointType, endpoint: endpoint })
        }

        switch (endpointOptions.endpointType) {
            case "ECHO_CAMERA_ENDPOINT":
                createCameraEndpoint()
                break
            case "ECHO_SCREEN_ENDPOINT":
                createScreenEndpoint()
                break

            case "VIEW_CAMERA_ENDPOINT":
                createViewerEndpoint()
                break
            case "VIEW_SCREEN_ENDPOINT":
                createViewerEndpoint()
                break
            default:
                console.error("Unknown endpoint type: " + endpointOptions.endpointType)
        }
    },

    clearActiveStreams({state}) {
        state.activeStreams = []
    },

    openRecordingEndpoints({state}, eventOptions) {
        const constraints = {video: {deviceId: {exact: state.currentCamera.deviceId}}, audio: true}
        state.mediaDevices
            .getUserMedia(constraints)
            .then(stream => { state.cameraAvailable = true; loadCameraStream(stream); })
            .catch(error => console.error(error))

        const loadCameraStream = function (cameraStream) {
            const constraints = {video: {cursor: "always"}, audio: false}
            navigator.mediaDevices
                .getDisplayMedia(constraints)
                .then(stream => { state.screenAvailable = true; loadScreenStream(cameraStream, stream); })
                .catch(error => console.error(error))
        }

        const loadScreenStream = function (cameraStream, screenStream) {
            createRecordingEndpoint("RECORDING_CAMERA_ENDPOINT", { audio: true, video: true }, cameraStream)
            createRecordingEndpoint("RECORDING_SCREEN_ENDPOINT", { audio: false, video: true }, screenStream)
        }

        const createRecordingEndpoint = function (endpointType, mediaConstraints, stream) {
            const offerFunction = function (error, offer) {
                if (error) { return console.error('Error generating the offer', error) }
                const data = ((offer != null && typeof offer == 'object') && ("sdp" in offer)) ? offer.sdp : offer
                let offerMessage = {
                    action: 'OPEN_ENDPOINT',
                    endpoint: endpointType,
                    offer: data,
                    options: eventOptions
                }
                if (state.transportConnection.readyState === WebSocket.OPEN) { state.transportConnection.send(JSON.stringify(offerMessage)) }
            }

            const options = {
                localVideo: null,
                videoStream: stream,
                peerConnection: createPeerConnection(state, offerFunction),
                remoteVideo: null,
                mediaConstraints: mediaConstraints,
                onicecandidate: function (candidate) {
                    let message = {
                        action: 'ICE_CANDIDATE',
                        endpoint: endpointType,
                        candidate: candidate,
                        options: null
                    }
                    if (state.transportConnection.readyState === WebSocket.OPEN) { state.transportConnection.send(JSON.stringify(message)) }
                }
            }
            const endpoint = new kurentoUtils.WebRtcPeer.WebRtcPeerSendonly(options,
                function (error) {
                    if (error) {
                        return console.error(error)
                    }
                    endpoint.generateOffer(offerFunction)
                })
            state.endpoints.push({ actorID: state.actorID, endpointType: endpointType, endpoint: endpoint })
        }
    },
}

function showStream(stream, endpointOptions) {
    if ("localVideo" in endpointOptions && endpointOptions.localVideo != null) {
        if ("srcObject" in endpointOptions.localVideo) {
            try {
                endpointOptions.localVideo.srcObject = stream
            } catch (e) {
                console.error(e)
                endpointOptions.localVideo.src = URL.createObjectURL(stream)
            }
        } else {
            endpointOptions.localVideo.src = URL.createObjectURL(stream)
        }
    }
}

function createPeerConnection(state, offerFunction) {
    const peerConnection = new RTCPeerConnection({iceServers: state.iceServers})

    peerConnection.onconnectionstatechange = function (event) {
        console.log(
            new Date()
            + " onconnectionstatechange: "
            + JSON.stringify(event)
            + " state: " + peerConnection.connectionState
        )
        if (peerConnection.connectionState === "connected") {
            state.reconnectRTC = false
        }
        if (peerConnection.connectionState === "failed") {
            state.reconnectRTC = true
            peerConnection.restartIce()
            console.log(offerFunction)
            //peerConnection.createOffer({ iceRestart: true }).then(r => { offerFunction(null, r) })
            /*console.log("peerConnection.restartIce() after 25000")
            state.reconnectRTC = true
            setTimeout(()=> {
                peerConnection.createOffer({ iceRestart: true }).then(r => {
                    console.log(r)
                    offerFunction(null, r)
                })
            }, 25000)*/
        }
    }

    return peerConnection
}

const mutations = {
    setIceServers(state, iceServers) { state.iceServers = iceServers },
    setKeycloak(state, keycloak) {
        state.keycloak = keycloak
        state.actorID = keycloak.idTokenParsed.sub
        state.actorFullName = ("middle_name" in keycloak.idTokenParsed) ?
            keycloak.idTokenParsed.family_name + " " + keycloak.idTokenParsed.given_name + " " + keycloak.idTokenParsed.middle_name
            : keycloak.idTokenParsed.family_name + " " + keycloak.idTokenParsed.given_name
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}