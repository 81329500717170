const state = () => ({
    showSettingsDialog: false,
})

const getters = {
    showSettingsDialog: (state) => {
        return state.showSettingsDialog
    },
}

const actions = {
    openSettingsDialog({state}) {
        state.showSettingsDialog = true
    },

    closeSettingsDialog({state}) {
        state.showSettingsDialog = false
    }
}

const mutations = { }

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}