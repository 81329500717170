<template>
  <div class="ma-5 rounded">
    <v-tabs v-model="tab" @change="onTabChange">
      <v-tab v-for="(item, index) in tabs" :key="index" class="pa-5">
        {{ item.name }}
      </v-tab>
      <v-tabs-items v-model="activeTab" class="pa-5">
        <v-tab-item v-for="(item, index) in tabs" :key="index">
          <component v-bind:is="item.content"
                     :ref="item.ref">
          </component>
        </v-tab-item>
      </v-tabs-items>
    </v-tabs>
  </div>
</template>

<script>
import PlannedEvents from "./student/PlannedEvents"
import SavedEvents from "./common/SavedEvents"

export default {
  name: "StudentUI",
  components: {
    PlannedEvents,
    SavedEvents
  },
  data: () => ({
    tabs: [
      {id: 1, name: 'Мои мероприятия', content: 'PlannedEvents', ref: 'plannedEvents'},
      {id: 2, name: 'Записи мероприятия', content: 'SavedEvents', ref: 'savedEvents'}
    ],
    activeTab: null,
    tab: null,
  }),
  methods: {
    onTabChange() {
      this.activeTab = this.tab
    },
  }
}
</script>

<style scoped>

</style>