<template>
  <v-container >    
    <div v-if="sessions.length === 1" class="wrapper1">
      <div class="video_box1" v-bind:class="{ attention: showAttention(item) }" v-for="(item, i) in sessions" :key="item.studentID" @click="emitToggleView(i)">
        <div class="video_wrapper">
          <div class="video">
            <video class ="videoCamera" poster="@/image/poster.png" autoplay muted
                   :src-object.prop.camel="readStream('camera', item.studentID)">
            </video>
          </div>
          <div class="video_overlays">
            <video class ="videoscreen" poster="@/image/poster.png" autoplay muted
                   :src-object.prop.camel="readStream('screen', item.studentID)">
            </video>
          </div>
          <div class="video_overlays_name">
            <div class="actor-name text-truncate"> {{item.actorName}} </div>
          </div>          
          
            <v-btn class="boxVideoBottom"
              color="primary"
              icon="mdi-bell-alert"
              size="x-large"
            ></v-btn>
          
        </div>
      </div>
      <v-btn class="boxVideoBottom"
              color="primary"
              icon="mdi-bell-alert"
              size="x-large"
            ></v-btn>
    </div>
    
    <div v-else-if="sessions.length === 2">
      <div class="wrapper2 d-none d-sm-none d-md-flex">
        <div class="video_box2" v-for="(item, i) in sessions" :key="item.studentID" @click="emitToggleView(i)">
          <div class="video_wrapper">
            <div class="video">
              <video class ="videoCamera" poster="@/image/poster.png" autoplay muted
                    :src-object.prop.camel="readStream('camera', item.studentID)">
              </video>
            </div>
            <div class="video_overlays">
              <video class ="videoscreen" poster="@/image/poster.png" autoplay muted
                    :src-object.prop.camel="readStream('screen', item.studentID)">
              </video>
            </div>
            <div class="video_overlays_name">
              <div class="actor-name text-truncate"> {{item.actorName}} </div>
            </div>         
          </div>
        </div> 
      </div>
      <div class="d-flex d-sm-flex d-md-none d-lg-none d-xl-none">
        <v-row>
          <v-col sm="12" cols="12">
            <div class="video_box2 mb-5" v-for="(item, i) in sessions" :key="item.studentID" @click="emitToggleView(i)">
              <div class="video_wrapper">
                <div class="video">
                  <video class ="videoCamera" poster="@/image/poster.png" autoplay muted
                        :src-object.prop.camel="readStream('camera', item.studentID)">
                  </video>
                </div>
                <div class="video_overlays">
                  <video class ="videoscreen" poster="@/image/poster.png" autoplay muted
                        :src-object.prop.camel="readStream('screen', item.studentID)">
                  </video>
                </div>  
                <div class="video_overlays_name">
                  <div class="actor-name text-truncate"> {{item.actorName}} </div>
                </div>    
              </div>
            </div> 
          </v-col>
        </v-row>
      </div>     
    </div>        

    <div v-else-if="3 <= sessions.length && sessions.length <= 4">
      <div class="d-none d-sm-none d-md-none d-lg-flex">
        <div class="wrapper3_4">
          <div class="video_box2_4" v-for="(item, i) in sessions" :key="item.studentID" @click="emitToggleView(i)">
            <div class="video_wrapper">
              <div class="video">
                <video class ="videoCamera" poster="@/image/poster.png" autoplay muted
                      :src-object.prop.camel="readStream('camera', item.studentID)">
                </video>
              </div>
              <div class="video_overlays">
                <video class ="videoscreen" poster="@/image/poster.png" autoplay muted
                      :src-object.prop.camel="readStream('screen', item.studentID)">
                </video>
              </div>  
              <div class="video_overlays_name">
                <div class="actor-name text-truncate"> {{item.actorName}} </div>
              </div>       
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex d-sm-flex d-md-flex d-lg-none d-xl-none">
        <v-row>
          <v-col md="6" sm="12" cols="12">
            <div class="video_box2 mb-5" v-for="(item, i) in sessions" :key="item.studentID" @click="emitToggleView(i)">
              <div class="video_wrapper">
                <div class="video">
                  <video class ="videoCamera" poster="@/image/poster.png" autoplay muted
                        :src-object.prop.camel="readStream('camera', item.studentID)">
                  </video>
                </div>
                <div class="video_overlays">
                  <video class ="videoscreen" poster="@/image/poster.png" autoplay muted
                        :src-object.prop.camel="readStream('screen', item.studentID)">
                  </video>
                </div>  
                <div class="video_overlays_name">
                  <div class="actor-name text-truncate"> {{item.actorName}} </div>
                </div>     
              </div>
            </div> 
          </v-col>
        </v-row>
      </div>
    </div>

    <div v-else-if="sessions.length >= 5 && sessions.length <= 9">
      <div class="d-none d-sm-none d-md-none d-lg-flex">
        <div class="wrapper5_9">
          <div class="video_box" v-for="(item, i) in sessions" :key="item.studentID" @click="emitToggleView(i)">
            <div class="video_wrapper">
              <div class="video">
                <video class ="videoCamera" poster="@/image/poster.png" autoplay muted
                      :src-object.prop.camel="readStream('camera', item.studentID)">
                </video>
              </div>
              <div class="video_overlays">
                <video class ="videoscreen" poster="@/image/poster.png" autoplay muted
                      :src-object.prop.camel="readStream('screen', item.studentID)">
                </video>
              </div>  
              <div class="video_overlays_name">
                <div class="actor-name text-truncate"> {{item.actorName}} </div>
              </div>       
            </div>
          </div> 
        </div>       
      </div>
      <div class="d-flex d-sm-flex d-md-flex d-lg-none d-xl-none">
          <v-row>
            <v-col md="6" sm="12" cols="12">
              <div class="video_box2 mb-5" v-for="(item, i) in sessions" :key="item.studentID" @click="emitToggleView(i)">
                <div class="video_wrapper">
                  <div class="video">
                    <video class ="videoCamera" poster="@/image/poster.png" autoplay muted
                          :src-object.prop.camel="readStream('camera', item.studentID)">
                    </video>
                  </div>
                  <div class="video_overlays">
                    <video class ="videoscreen" poster="@/image/poster.png" autoplay muted
                          :src-object.prop.camel="readStream('screen', item.studentID)">
                    </video>
                  </div> 
                  <div class="video_overlays_name">
                    <div class="actor-name text-truncate"> {{item.actorName}} </div>
                  </div>         
                </div>
              </div> 
            </v-col>
          </v-row>
      </div>
    </div>    
  </v-container>
</template>

<script>
import {mapGetters} from "vuex";


export default {
  components: { },
  name: "ViewGridStudentSessions",
  props: { sessions: Array },
  computed: {
    ...mapGetters('application', { activeStreams: 'activeStreams', })
  },
  methods: {
    emitToggleView(sessionIndex) { this.$emit('toggle-view', sessionIndex) },
    readStream(type, id) {
      let activeStreamID = type === "camera" ? "VIEW_CAMERA_ENDPOINT-" + id : "VIEW_SCREEN_ENDPOINT-" + id
      for (let i = 0; i < this.activeStreams.length; i++) {
        if (this.activeStreams[i].id === activeStreamID) {
          return this.activeStreams[i].stream
        }
      }
      return null
    },

    showAttention(item) {
        let itemIndex = this.$store.state.application.activeEvents.indexOf(item)
        if (itemIndex === -1) {
          console.error("item not found", item)
          return false
        }
        let foundItem = this.$store.state.application.activeEvents[itemIndex]
      return (this.notFlowing(foundItem) || foundItem.disconnectedWS) && !item.proctorAlreadySeenAttention
      },
    notFlowing(item) {
      return (item.NOT_FLOWING.RECORDING_CAMERA_ENDPOINT.AUDIO
              || item.NOT_FLOWING.RECORDING_CAMERA_ENDPOINT.VIDEO
              || item.NOT_FLOWING.RECORDING_SCREEN_ENDPOINT.AUDIO
              || item.NOT_FLOWING.RECORDING_SCREEN_ENDPOINT.VIDEO)
    }
  }
}
</script>

<style scoped>
.wrapper1 {
display: grid;
}

.wrapper2 {
  display: grid;
  /*grid-template-columns: repeat(auto-fill, minmax(530px, 1fr));*/
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px 10px;
  align-items: center;
  align-content: center;
}

.wrapper3_4 {
  display: grid;
  /*grid-template-columns: repeat(2, minmax(530px, 1fr));*/
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-gap: 30px 10px;
  align-items: center;
  align-content: center;
}

.wrapper5_9 {
  display: grid;
  /*grid-template-columns: repeat(3, minmax(350px, 1fr));*/
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  grid-gap: 30px 10px;
  align-items: center;
  align-content: center;
}

.video_box {
  aspect-ratio: 16 / 9;
  position: relative;
  /*width: 100%;*/
  height: 24vh;
  overflow: hidden;
  justify-self: center;
}

.video_box1 {
  aspect-ratio: 16 / 9;
  position: relative;
  width: 100%;
  height: 80vh;
  overflow: hidden;
  justify-self: center;
}

.video_box2 {
  aspect-ratio: 16 / 9;
  position: relative;
  width: 100%;
  /*height: 40vh;*/
  overflow: hidden;
  justify-self: center;
}

.video_box2_4 {
  aspect-ratio: 16 / 9;
  position: relative;
  /*width: 100%;*/
  height: 40vh;
  overflow: hidden;
  justify-self: center;
}

.video_wrapper {
  position: relative;
  /*width: 100%;*/
  height: 100%;
}

.video {
  width: 100%;
  aspect-ratio: 16 / 9;
  position: absolute;
  top: -25px;  
}

.videoCamera {
  width: 100%;
  position: absolute;
  top: -25px;
}

.video_overlays {
  position: absolute;
  width: 35%;
  min-height: 35%;
  z-index: 300000;
  top: 0px;
  right: 0px;
  text-align: center;
}

.videoscreen {
  width: 100%;
  min-height: 100%;
  z-index: 300000;
}

.overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  opacity: 0.5;
  transition: .3s ease;
  background-color: #599df7;
  min-height: 25%;
  width: 100%;
}

.overlay1 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  opacity: 0.5;
  transition: .3s ease;
  background-color: #006cb4;
  height: 10%;
}

.pictograms {
  position: absolute;
  bottom: 0%;
  left: 0;
  height: 10%;
  width: 100%;
  opacity: 1;
  z-index: 1;
  padding: 2px;
  display: flex;
}

.pictogram_icon {
  position: absolute;
  bottom: 7%;
}

.boxVideoBottom {
  height: 70px;
  width: 70px;
  position: absolute;
  right: 0;
  bottom: 0;
}

.nameBoxVideoBottom {
  color: #fff;
  max-width: 150px;
}
.video_overlays_name{
    position: absolute;
    top: 10px;
    left: 0;
    width: 40%;
    background-color: #006cb4;
    color: #fff;
    font-size: 18px;
    padding: 10px;
    opacity: 0.5;
}
.attention {
    border: 5px solid red;
}

@media (min-width: 1264px) and (max-widht: 1904px) {
  .actor-name {
    width: 200px;
  }
}
@media (min-width: 960px) and (max-widht: 1264px) {
  .video_overlays_name {
    width: 60%;
  }
}
@media (min-width: 600px) and (max-widht: 960) {
  .video_overlays_name {
    width: 40%;
  }
}
@media (max-widht: 600) {
  .video_overlays_name {
    width: 70%;
  }
  .actor-name {
    width: 200px;
  }
  .nameUserList {
    width: 100%;
  }
}
</style>