<template>
  <v-app>
    <v-card color="white"
            flat
            height="100%"
            tile >
      <v-toolbar >
        <div style="display:flex;">
          <v-img src="./image/logo.png" style="width: 25px;"></v-img>  
          <v-divider vertical style="margin-left: 10px; margin-right: 10px;"></v-divider>
          <div class="img_logo">
            <v-img src="./image/logo1.png" style="width: 95px;"></v-img>  
          </div>        
        </div>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon  v-bind="attrs" v-on="on">
              <v-icon>{{iconName()}}</v-icon>
            </v-btn>
          </template>
          <span>
            <Actor :keycloak="keycloak" :roleName="roleName()"/>
          </span>
        </v-tooltip>        
        <Settings />    
        <v-btn icon @click="logout" id="logout">
          <v-icon>mdi-home-export-outline</v-icon>
        </v-btn>
      </v-toolbar>
      <div v-if="canUseApplication()">
        <ProctorUI v-if="isCurrentUserProctor()" />
        <StudentUI v-else />        
      </div>
      <H1 v-else>
        Invalid credentials
      </H1>
    </v-card>
  </v-app>
</template>

<script>
import Actor from './components/common/Actor'
import ProctorUI from './components/ProctorUI'
import StudentUI from './components/StudentUI'
import Settings from './components/common/Settings'

export default {
  name: 'App',
  props: ['keycloak'],
  components: {
    Actor,
    ProctorUI,
    StudentUI,
    Settings
  },
  mounted() {
    this.$store.dispatch('application/setupCameraDevices')
    this.$store.commit('application/setKeycloak', this.keycloak)
    this.$store.dispatch('application/openConnection')
    this.$store.dispatch('application/loadIceServers')
  },
 data () {
      return {
        dialog: false,
      }
    },
  methods: {
    canUseApplication() {
      if (!(this.keycloak.clientId in this.keycloak.resourceAccess)) return false
      console.log('Сборка от 14.12.2023')
      return this.keycloak.resourceAccess[this.keycloak.clientId].roles.length === 1
    },
    isCurrentUserProctor() {
      return this.keycloak.resourceAccess[this.keycloak.clientId].roles[0] === this.keycloak.clientId + "_" + this.keycloak.clientId
    },
    roleName() {
      return this.isCurrentUserProctor() ? "Проктор" : "Студент"
    },
    iconName() {
      return this.roleName() === "Проктор" ? "mdi-alpha-p-circle-outline" : "mdi-alpha-s-circle-outline"
    },
    logout() {
      //todo close all endpoints
      this.$store.dispatch('application/closeConnection')
      this.keycloak.logout()
    },
  }
};
</script>
<style scoped>
.img_logo {
  display: flex;
  align-self: center;
}
</style>
