<template>
    <div class="SideBarContent">
        <h4 class="text-center" style="color:#313131">Студенты ONLINE</h4>
        <v-list dense>          
            <v-list-item v-for="item in sessions" :key="item.studentID" class="userListOnline">
                <div class="iconUserList">
                    <v-btn icon 
                        title="Соединение восстановлено" 
                        color="primary"
                        @click="proctorAlreadySeenAttention(item)">
                            <v-icon>mdi-wifi-strength-4-alert</v-icon>
                        </v-btn>
                    <!--v-menu
                    offset-y
                    :offset-x="offset"
                    :rounded="rounded"                
                    >
                        

                        <template v-slot:activator="{ on, attrs }">
                                <v-btn icon
                                title="Действия над пользователем" 
                                v-bind="attrs"
                                v-on="on">
                                    <v-icon>mdi-account-cog</v-icon>
                                </v-btn>                                
                        </template>
                        <v-list class="menuUserSetting">                        
                            <div class="btnUserSetting">
                                <v-btn small>
                                    <v-icon color="red">mdi-account-off</v-icon><span class="text-caption">Заблокировать</span>
                                </v-btn>
                            </div>
                            <div class="btnUserSetting">
                                <v-btn small>
                                    <v-icon color="yellow">mdi-alert-outline</v-icon><span class="text-caption">Предупреждение</span>
                                </v-btn>
                            </div>
                            <div class="btnUserSetting">
                                <v-dialog
                                    v-model="dialog"
                                    width="500"
                                    >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn v-bind="attrs" small
                                        v-on="on">
                                            <v-icon>mdi-email-outline</v-icon><span class="text-caption">Отправить сообщение</span>
                                        </v-btn>
                                    </template>
                                    <SendMail />
                                </v-dialog>
                            </div>                            
                            <div class="btnUserSetting">
                                <v-btn small>
                                    <v-icon>mdi-play</v-icon><span class="text-caption">Перейти к записи</span>
                                </v-btn>
                            </div>                        
                        </v-list>
                    </v-menu-->
                </div>
                <div class="nameUserList text-truncate d-inline-block">
                    <v-list-item-title class="nameOnlineUser" v-bind:class="{ ATTENTION: showAttention(item) }">{{item.actorName}}</v-list-item-title>
                </div>
            </v-list-item>
        </v-list> 
    </div>
</template>

<script>
//import SendMail from './SendMail.vue'

export default {
    components: {
//    SendMail
    },
    name: "SideBarForGridStudents",
    props: { sessions: Array },
    methods: {
      showAttention(item) {
        let itemIndex = this.$store.state.application.activeEvents.indexOf(item)
        if (itemIndex === -1) {
          console.error("item not found", item)
          return false
        }
        let foundItem = this.$store.state.application.activeEvents[itemIndex]
        return (this.notFlowing(foundItem) || foundItem.disconnectedWS) && !item.proctorAlreadySeenAttention
      },
      notFlowing(item) {
        return (item.NOT_FLOWING.RECORDING_CAMERA_ENDPOINT.AUDIO
            || item.NOT_FLOWING.RECORDING_CAMERA_ENDPOINT.VIDEO
            || item.NOT_FLOWING.RECORDING_SCREEN_ENDPOINT.AUDIO
            || item.NOT_FLOWING.RECORDING_SCREEN_ENDPOINT.VIDEO)
      },
      proctorAlreadySeenAttention(item) {
        let itemIndex = this.$store.state.application.activeEvents.indexOf(item)
        if (itemIndex === -1) {
          console.error("item not found", item)
          return false
        }
        let foundItem = this.$store.state.application.activeEvents[itemIndex]
        if (!this.notFlowing(foundItem) && !foundItem.disconnectedWS) {
          foundItem.proctorAlreadySeenAttention = true
        }
      }
    },
    data: () => ({      
        offset: true,
        })
    }
</script>

<style scopped>
.SideBarContent {
    padding: 10px;
}
.btnUserSetting {
    padding: 10px;
}
.nameOnlineUser {
    flex-wrap: wrap;

}
.userListOnline {
    padding-left: 0;
    padding-right: 0;
}
.iconUserList {
    padding-right: 5px;
}
.nameUserList {
    padding-left: 5px;
    width: 160px;
}
.ATTENTION {
  margin: 10px;
  outline: 4px solid #dd1144;
  background: #f0f0f0;
}
</style>