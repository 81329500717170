<template> 
  <v-dialog
    hide-overlay :fullscreen="$vuetify.breakpoint.mobile"  v-model="dialog" class="d-sm-none d-md-flex dialog"
      max-width="55%"
      transition="dialog-top-transition"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        icon
        v-bind="attrs"
        v-on="on"
      >
        <v-icon>mdi-cog-outline</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-system-bar dark color="primary" class="text-left  pa-4">
        <span class="text-uppercase letter-spacing text-caption">Проверка соединения</span>
        <v-spacer></v-spacer>
        <!--v-btn icon color="white float-right" @click="closeDialog"> <v-icon small>mdi-window-close</v-icon></v-btn-->
      </v-system-bar>
      <v-container>
        <v-row>
          <v-col md="8" sm="12" cols="12">
            <v-row>
              <v-col>
                <EchoEndpointsView @check-echo-started="readNetworkSpeed"/>
              </v-col>
            </v-row>
          </v-col>
          <v-col class="grey lighten-3"  md="4" sm="12" cols="12">
            <v-row>
              <v-col>
                <div class="pt-5">
                  <v-row>
                    <v-col md="10" sm="10" cols="10">
                      <v-icon color="blue darken-2" left>mdi-lan-connect</v-icon>
                      <small class="letter-spacing">Сервер доступен</small>
                    </v-col>
                    <v-col md="2" sm="2" cols="2">
                      <div class="float-right">
                        <v-icon v-if="$store.state.application.transportReady"
                                color="green darken-2" right>
                          mdi-check
                        </v-icon>
                        <v-icon v-else color="red" right>mdi-window-close</v-icon>
                      </div>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col md="10" sm="10" cols="10">
                      <v-icon color="blue darken-2" left>mdi-rocket-launch-outline</v-icon>
                      <small class="letter-spacing">Скорость передачи</small>
                    </v-col>
                    <v-col md="2" sm="2" cols="2">
                      <div class="float-right">{{uploadSpeed}}
                      </div>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col md="10" sm="10" cols="10">
                      <v-icon color="blue darken-2" left>mdi-rocket-launch-outline</v-icon>
                      <small class="letter-spacing">Скорость приёма</small>
                    </v-col>
                    <v-col md="2" sm="2" cols="2">
                      <div class="float-right">{{downloadSpeed}}
                      </div>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col md="10" sm="10" cols="10">
                      <v-icon color="blue darken-2" left>mdi-camera-plus-outline</v-icon>
                      <small class="letter-spacing">Доступность камеры</small>
                    </v-col>
                    <v-col md="2" sm="2" cols="2">
                      <div class="float-right">
                        <v-icon v-if="$store.state.application.cameraAvailable" color="green darken-2" right>mdi-check </v-icon>
                        <v-icon v-else color="red" right>mdi-window-close</v-icon>
                      </div>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col md="10" sm="10" cols="10">
                      <v-icon color="blue darken-2" left>mdi-monitor</v-icon>
                      <small class="letter-spacing">Доступность экрана</small>
                    </v-col>
                    <v-col md="2" sm="2" cols="2">
                      <div class="float-right">
                        <v-icon v-if="$store.state.application.screenAvailable" color="green darken-2" right>mdi-check </v-icon>
                        <v-icon v-else color="red" right>mdi-window-close</v-icon>
                      </div>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col md="10" sm="10" cols="10">
                      <v-icon color="blue darken-2" left>mdi-camera-wireless-outline</v-icon>
                      <small class="letter-spacing">Получен отклик по камере</small>
                    </v-col>
                    <v-col md="2" sm="2" cols="2">
                      <div class="float-right">
                        <v-icon v-if="$store.state.application.cameraStreamAvailable" color="green darken-2" right>mdi-check </v-icon> <!-- TODO -->
                        <v-icon v-else color="red" right>mdi-window-close</v-icon>
                      </div>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col md="10" sm="10" cols="10">
                      <v-icon color="blue darken-2" left>mdi-monitor-multiple</v-icon>
                      <small class="letter-spacing">Получен отклик по экрану</small>
                    </v-col>
                    <v-col md="2" sm="2" cols="2">
                      <div class="float-right">
                        <v-icon v-if="$store.state.application.screenStreamAvailable" color="green darken-2" right>mdi-check </v-icon> <!-- TODO -->
                        <v-icon v-else color="red" right>mdi-window-close</v-icon>
                      </div>
                    </v-col>
                  </v-row>
                  <!--<v-row>
                    <v-col md="10" sm="10" cols="10">
                      <v-icon  color="blue darken-2" left>mdi-volume-source</v-icon>
                      <small class="letter-spacing">Звук на сервере</small>
                    </v-col>
                    <v-col md="2" sm="2" cols="2">
                      <div class="float-right">
                        <v-icon v-if="" color="green darken-2" right>mdi-check </v-icon>
                        <v-icon v-else color="red" right>mdi-window-close</v-icon>
                      </div>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col md="10" sm="10" cols="10">
                      <v-icon  color="blue darken-2" left>mdi-account-tie-voice-outline</v-icon>
                      <small class="letter-spacing">Клиент слышит звук </small>
                    </v-col>
                    <v-col md="2" sm="2" cols="2">
                      <div class="float-right">
                        <v-icon v-if="" color="green darken-2" right>mdi-check </v-icon>
                        <v-icon v-else color="red" right>mdi-window-close</v-icon>
                      </div>
                    </v-col>
                  </v-row>-->
                  <!--<v-row>
                    <v-col md="10" sm="10" cols="10">
                      <v-icon  color="blue darken-2" left>mdi-face-recognition</v-icon>
                      <small class="letter-spacing">В камере один человек</small>
                    </v-col>
                    <v-col md="2" sm="2" cols="2">
                      <div class="float-right">
                        <v-icon v-if="false" color="green darken-2" right>mdi-check </v-icon>  TODO 
                       <v-icon v-else color="red" right>mdi-window-close</v-icon>
                      </div>
                    </v-col> 
                  </v-row>-->
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            color="primary"
            text
            @click="dialog = false" >
          ОК
        </v-btn>
      </v-card-actions>
    </v-card> 
  </v-dialog> 
</template>

<script>
import EchoEndpointsView from './EchoEndpointsView'
import {mapGetters} from "vuex"

export default {
  name: "Settings",
  components: { EchoEndpointsView },
  data: () => ({
    dialog: false,
    downloadSpeed: ' - ',
    uploadSpeed: ' - ',
  }),
  computed: {
    ...mapGetters('applicationUI', { showSettingsDialog: 'showSettingsDialog', })
  },
  methods: {
    closeDialog() {
      this.downloadSpeed = ' - '
      this.uploadSpeed = ' - '
      this.$store.state.application.cameraAvailable = false
      this.$store.state.application.screenAvailable = false
      this.$store.state.application.cameraStreamAvailable = false
      this.$store.state.application.screenStreamAvailable = false
      this.$store.dispatch('application/closeEndpoint', "ECHO_CAMERA_ENDPOINT")
      this.$store.dispatch('application/closeEndpoint', "ECHO_SCREEN_ENDPOINT")
      this.$store.dispatch('applicationUI/closeSettingsDialog')
    },
    acceptSettings() {
      this.closeDialog()
    },
    readNetworkSpeed() {
      let self = this
      let fileSizeInBytes = 500000
      let token = this.$store.state.application.keycloak.token
      let downloadURL = "/api/download/" + fileSizeInBytes
      let uploadURL = "/api/upload"

      let startDownloadTime = new Date().getTime()
      fetch(downloadURL,
          { headers: { "Authorization": "Bearer " + token }})
          .then(() => {
            let duration = (new Date().getTime() - startDownloadTime) / 1000
            let bitsLoaded = fileSizeInBytes * 8
            self.downloadSpeed = ((bitsLoaded / duration) / 1000000).toFixed(2)
          }).catch(error => { throw new Error(error) })

      let chars =
          "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789~!@#$%^&*()_+`-=[]{}|;':,./<>?"
      let generatedData = ""
      for (let index = 0; index < fileSizeInBytes; index++) {
        generatedData += chars.charAt(Math.floor(Math.random() * chars.length))
      }
      let data = { data: generatedData }
      let startUploadTime = new Date().getTime()
      fetch(uploadURL,
          { method: 'POST', headers: { "Authorization": "Bearer " + token }, body: JSON.stringify(data) })
          .then(() => {
            let duration = (new Date().getTime() - startUploadTime) / 1000
            let bitsLoaded = fileSizeInBytes * 8;
            self.uploadSpeed = ((bitsLoaded / duration) / 1000000).toFixed(2)
          }).catch(error => { throw new Error(error) })
    },
  }
}
</script>

<style scoped>

</style>