<template>
    <div>
        <iframe id="calendarFrame"
                src="https://service.samsmu.ru/resourceManagement/resource-calendar.jsp?sep=y&pr=y"
                @load="afterPageLoad()"
                class="iframeDateEvents">
        </iframe>
    </div>
</template>

<script>
export default {
  name: "SelectDateEvent",
  methods: {
    afterPageLoad() {
      let token = this.$store.state.application.keycloak.token
      let calendarFrame = document.getElementById("calendarFrame")
      if (calendarFrame != null) {
        if (calendarFrame.src.indexOf('resource-calendar.jsp') > 0) {
          calendarFrame.contentWindow.postMessage(token, '*')
        }
      }
    }
  }
}
</script>

<style>
.iframeDateEvents{
    width: 100%;
    height: 800px;
    border-width: 0px;
}
</style>