<template>         
  <div class="white--text" align="center">
    <h3 class="pt-2">{{ $store.state.application.actorFullName }}</h3>
    <small class="">{{roleName}}</small>
    <v-divider color="white"></v-divider> 
    <small class="py-2">{{ actorMail() }}</small>
  </div>      
</template>

<script>
export default {
  name: "Actor",
  props: ['keycloak', 'roleName'],
  methods: {
    actorMail() {
      return this.keycloak.idTokenParsed.emailVerified ? this.keycloak.idTokenParsed.email : this.keycloak.idTokenParsed.email + " [не проверена]"
    }
  }
}
</script>

<style scoped>

</style>