<template>
  <div class="ma-5">
    <v-tabs v-model="tab" @change="onTabChange">
      <v-tab v-for="(item, index) in tabs" :key="index" class="pa-5">
        {{ item.name }}
      </v-tab>
      <v-tabs-items v-model="activeTab" class="pa-5">
        <v-tab-item v-for="(item, index) in tabs" :key="index">
          <component v-bind:is="item.content"
                     :ref="item.ref">
          </component>
        </v-tab-item>
      </v-tabs-items>
    </v-tabs>
  </div>
</template>

<script>
import PlannedEvents from "./proctor/PlannedEvents"
import ActiveEvents from "./proctor/ActiveEvents"
import SavedEvents from "./common/SavedEvents"
import SelectDateEvent from "./proctor/SelectDateEvent"

export default {
  name: "ProctorUI",
  components: {
    PlannedEvents,
    ActiveEvents,
    SavedEvents,
    SelectDateEvent
  },
  data: () => ({
    tabs: [
      {id: 1, name: 'Запланировать мероприятие', content: 'SelectDateEvent', ref: 'SelectDateEvent'},      
      {id: 2, name: 'Активные мероприятия', content: 'ActiveEvents', ref: 'activeEvents'},
      {id: 3, name: 'Записанные мероприятия', content: 'SavedEvents', ref: 'savedEvents'},
      {id: 4, name: 'Все мероприятия', content: 'PlannedEvents', ref: 'plannedEvents'}
    ],
    activeTab: null,
    tab: null,
  }),
  methods: {
    onTabChange() {
      this.activeTab = this.tab
    },
  }
}
</script>

<style scoped>

</style>