<template>
  <div>
    <v-alert color="blue-grey" class="py-0" dark dense prominent >
      <v-row>
        <v-col class="d-none d-sm-flex" lg="9" md="8" sm="7" cols="12">
          <v-btn class="ma-2" fab dark color="grey" disabled>
            <v-icon class="" large>mdi-school</v-icon>
          </v-btn>
          <span class="text-uppercase py-6">Выбор мероприятия</span>
        </v-col>
        <v-col class="mt-2" lg="3" md="4" sm="5" cols="12">
          <v-menu ref="menu" class="datamenu" v-model="menu"
                  :close-on-content-click="false"
                  :return-value.sync="date"
                  min-width="auto" offset-y transition="scale-transition" >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field v-model="date"  format="DD/MM/YYYY"
                            v-bind="attrs"
                            v-on="on"
                            label="Дата мероприятия" prepend-icon="mdi-calendar" readonly />
            </template>
            <v-date-picker v-model="date" first-day-of-week="1" locale="ru-us"  :show-current="false"
                           no-title scrollable>
              <v-spacer />
              <v-btn color="primary" text @click="menu = false">
                Отмена
              </v-btn>
              <v-btn color="primary" text @click="applyDate">
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
    </v-alert>
    <v-simple-table>
      <template v-slot:default>
        <thead>
        <tr>
          <th class="text-left">Мероприятие</th>
          <th class="text-left">Студент</th>          
          <th class="text-left">Дата</th>
        </tr>
        </thead>
        <tbody>
          <tr v-for="item in events" :key="item" @click="onSelectEvent(item)">          
            <td>{{ item.eventName }}</td>
            <td>{{ item.studentFullName }}</td>         
            <td>{{ recordingStartTime(item) }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <v-dialog v-model="dialog" fullscreen transition="dialog-bottom-transition">
      <ViewSavedStreams :selectedEvent="selectedEvent"
                        :cameraSource="cameraSource"
                        :screenSource="screenSource"
                        @closeViewSavedStreamsDialog="closeViewSavedStreamsDialog"/>
    </v-dialog>
  </div>
</template>

<script>
import ViewSavedStreams from './ViewSavedStreams'

export default {
  name: "SavedEvents",
  components: { ViewSavedStreams },
  data: () => ({
    date: document.eventsDate,
    menu: false,
    events: [],
    showSpinner: false,
    dialog: false,
    selectedEvent: null,
    cameraSource: null,
    screenSource: null
  }),
  mounted() {
    this.applyDate()
  },
  methods: {
    applyDate() {
      this.showSpinner = true
      let oldEventsDate = this.date
      this.$refs.menu.save(this.date)
      let self = this
      let token = this.$store.state.application.keycloak.token
      fetch("/api/savedEvents?date=" + this.date, { headers: { 'Authorization': 'Bearer ' + token }} )
          .then(response => (response.status === 200) ? Promise.resolve(response) : Promise.reject(new Error(response.statusText)) )
          .then(response => response.json())
          .then(function (json) {
            self.events = json;
            self.events.sort(function (a, b) { return a.eventName.localeCompare(b.eventName) || a.studentFullName.localeCompare(b.studentFullName) })
            self.showSpinner = false;
          })
          .catch(function (error) {
            console.error('Request failed', error);
            self.eventsDate = oldEventsDate;
            self.$refs.menu.save(oldEventsDate)
            self.showSpinner = false;
          })
    },
    recordingStartTime(item) {
      return item.recordingStartTime.length === 1 ? item.recordingStartTime[0] : item.recordingStartTime.join(", ")
    },
    onSelectEvent(eventItem) {
      this.selectedEvent = eventItem
      this.dataURL(this.createURL("RECORDING_CAMERA_ENDPOINT")).then(data => this.cameraSource = data)
      this.dataURL(this.createURL("RECORDING_SCREEN_ENDPOINT")).then(data => this.screenSource = data)
      this.dialog = true
      
    },
    async dataURL(url) {
      const response = await fetch(url, { headers: { 'Authorization': 'Bearer ' + this.$store.state.application.keycloak.token }})
      const blob = await response.blob()
      return URL.createObjectURL(blob)
    },
    createURL(endpointType) {
      return `api/stream/${endpointType}/${this.selectedEvent.sessionID}/${this.selectedEvent.eventID}/${this.selectedEvent.studentID}/${this.selectedEvent.recordingStartTime[0].replace(" ", "_")}`
    },
    closeViewSavedStreamsDialog() {
      this.selectedEvent = null
      this.cameraSource = null
      this.screenSource = null
      this.dialog = false
    },

  }
}
</script>

<style scoped>
</style>