<template>
      <v-card>
        <div>
          <v-btn class="float-right"
            icon
            @click="closeEventInfoDialog()" >
            <v-icon>mdi-window-close</v-icon>
          </v-btn>
        </div>
        <v-card-title class="hintInfoEventTitle">
          <span class="text-h5 text--blue">{{selectedEvent.eventName}}</span>
          <p><v-icon >mdi-calendar</v-icon> {{formatDate(selectedEvent.eventStartDate)}} - {{formatDate(selectedEvent.eventEndDate)}}</p>
        </v-card-title>
        <v-card-text>       
          <p>
            <b>Прокторы:</b>
            <v-simple-table>
              <template v-slot:default>
                <tbody>
                <tr v-for="proctor in eventParticipants.proctors" :key="proctor">
                  <td>{{ proctor.fullName }}</td>
                </tr>
                </tbody>
              </template>
            </v-simple-table>
          </p>
          <p>
            <b>Студенты:</b>
            <v-simple-table>
              <template v-slot:default>
                <tbody>
                <tr v-for="student in eventParticipants.students" :key="student">
                  <td>{{ student.fullName }}</td>
                  <td>группа {{ student.group }}</td>
                </tr>
                </tbody>
              </template>
            </v-simple-table>
          </p>
        </v-card-text>        
      </v-card>

</template>

<script>

  export default {
    props: {
      selectedEvent: Object,
      eventParticipants: Object
    },
    methods: {
      closeEventInfoDialog() { this.$emit('closeEventInfoDialog') },
      formatDate(stringDate) {
        let date = new Date(stringDate)
        return (date.getDate() > 9 ? date.getDate() : ("0" + date.getDate())) + "-" +
            (date.getMonth() + 1 > 9 ? date.getMonth() + 1 : ("0" + (date.getMonth() + 1))) + "-" +
            date.getFullYear() + " " +
            (date.getHours() > 9 ? date.getHours() : ("0" + date.getHours())) + ":" +
            (date.getMinutes() > 9 ? date.getMinutes() : ("0" + date.getMinutes()))
      }
    }
  }
</script>

<style>
.hintInfoEventTitle {
  justify-content: center;
}
</style>